import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import useMultiLanguageFieldValue from '../../../../../hooks/useMultiLanguageFieldValue';
import fireToast from '../../../../benit/central-ui/methods/fireToast';
import showConfirmPopup from '../../../../benit/central-ui/methods/showConfirmPopup';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation/LoadingAnimation';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { useDropzone } from 'react-dropzone';
import styles from './RIRealEstateObjectsDetailApp.module.css';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';

interface IProps {
    itemId: string;
}

const RIRealEstateObjectsDetailApp = ({ itemId }: IProps) => {
    const history = useHistory();
    const saveButton = useRef<any>(null);
    const shortExposeButton = useRef<any>(null);
    const notarButton = useRef<any>(null);
    const exposeButton = useRef<any>(null);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [dataTmp, setDataTmp] = useState<{ [x: string]: any } | null>({});
    const [newStakeholder, setNewStakeholder] = useState<{ [x: string]: any } | null>({ title: 'Herr' });
    const [editStakeholder, setEditStakeholder] = useState<{ [x: string]: any } | null>({});
    const [draggedImgId, setDraggedImgId] = useState<number>(0);
    const [publishedHomepage, setPublishedHomepage] = useState<boolean>(false);
    const { t } = useTranslation(['general', 'ri-property-management']);
    const { mlfv } = useMultiLanguageFieldValue();

    const {
        ri_real_estate_get_objekt,
        ri_real_estate_change_objekt,
        ri_real_estate_delete_objekt,
        ri_real_estate_upload_picture,
        ri_real_estate_delete_picture,
        ri_real_estate_get_texts,
        ri_real_estate_get_expose,
        ri_real_estate_get_pictures,
        ri_real_estate_get_stakeholder,
        ri_real_estate_create_stakeholder,
        ri_real_estate_update_stakeholder,
        ri_real_estate_delete_stakeholder,
        ri_real_estate_delete_all_stakeholder,
        ri_real_estate_document_stakeholder,
        ri_real_estate_get_stakeholder_list,
        ri_real_estate_get_stakeholder_confirmation_document,
        ri_real_estate_get_text_support,
    } = useActions();

    const {
        objekt,
        objektUpdated,
        objektDidInvalidate,
        objektDeleted,
        objektUpdating,
        texts,
        objektPictures,
        stakeholderDidInvalidate,
        stakeholderCreated,
        stakeholder,
        stakeholderDocumentSuccess,
        stakeholderDeleteAllSuccess,
        stakeholderDeleteSuccess,
        textSupport,
    } = useTypedSelector((state) => state.riRealEstateReducer);

    useEffect(() => {
        ri_real_estate_get_objekt(itemId);
        ri_real_estate_get_stakeholder(itemId);
        if (!objekt) {
            setDataTmp({});
            setPublishedHomepage(false);
        }
        document.addEventListener(
            'keypress',
            function (e) {
                console.log(e.key);
                console.log(e.keyCode);
                if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
                    e.preventDefault();
                    // Process the event here (such as click on submit button)
                }
            },
            false
        );
    }, []);

    useEffect(() => {
        if (objektDidInvalidate) {
            ri_real_estate_get_objekt(itemId);
        }
    }, [objektDidInvalidate]);

    useEffect(() => {
        if (stakeholderDidInvalidate) {
            ri_real_estate_get_stakeholder(itemId);
        }
    }, [stakeholderDidInvalidate]);

    useEffect(() => {
        if (objekt) {
            setDataTmp(objekt.data);
            setPublishedHomepage(objekt.publishedHomepage ? objekt.publishedHomepage : false);
        }
    }, [objekt]);

    useEffect(() => {
        if (objektUpdated) {
            fireToast('success', `Objekt gespeichert`);
            history.push(`/ri/real-estate/objects/`);
        }
    }, [objektUpdated]);

    useEffect(() => {
        if (stakeholderCreated) {
            fireToast('success', `Interessent angelegt`);
        }
    }, [stakeholderCreated]);

    useEffect(() => {
        if (stakeholderDocumentSuccess) {
            fireToast('success', `E-Mail versendet`);
        }
    }, [stakeholderDocumentSuccess]);

    useEffect(() => {
        if (stakeholderDeleteAllSuccess) {
            fireToast('success', `Alle Interssenten wurden gelöscht.`);
        }
    }, [stakeholderDeleteAllSuccess]);

    useEffect(() => {
        if (stakeholderDeleteSuccess) {
            fireToast('success', `Interessent gelöscht`);
        }
    }, [stakeholderDeleteSuccess]);

    useEffect(() => {
        if (objektDeleted) {
            fireToast('success', `Objekt gelöscht`);
            history.push(`/ri/real-estate/objects/`);
        }
    }, [objektDeleted]);

    useEffect(() => {
        if (acceptedFiles.length > 0 && objekt) {
            const picturesTmp: [{ [x: string]: any }] = JSON.parse(JSON.stringify(acceptedFiles));
            let pictures: any = dataTmp && dataTmp.pictures ? dataTmp.pictures : {};
            for (const pictureTmp of picturesTmp) {
                pictures[`${objekt.objectId}-${pictureTmp.path}`] = { path: `${objekt.objectId}-${pictureTmp.path}`, titlePicture: '' };
            }
            setDataTmp({
                ...dataTmp,
                pictures,
            });
            ri_real_estate_upload_picture(acceptedFiles, objekt.objectId);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (texts) {
            setDataTmp({
                ...dataTmp,
                descriptionNote: texts.propertyText,
            });
        }
    }, [texts]);

    useEffect(() => {
        if (textSupport && textSupport.textType && textSupport.textType === 'descriptionNoteLong') {
            setDataTmp({
                ...dataTmp,
                descriptionNoteLongKIText: textSupport.text ?? '',
            });
        }
    }, [textSupport]);

    configure({
        ignoreTags: ['input', 'select', 'textarea'],
        ignoreEventsCondition: () => false,
    });

    const keyMap = {
        SAVE: ['command+s', 'ctrl+s'],
        SHORTEXPOSE: ['command+k', 'ctrl+k'],
        EXPOSE: ['command+e', 'ctrl+e'],
        NOTAR: ['command+b', 'ctrl+b'],
    };

    const saveObject = useCallback((e) => {
        e.preventDefault();
        if (saveButton && saveButton.current) {
            saveButton.current.click()!;
        }
    }, []);

    const clickNotar = useCallback((e) => {
        e.preventDefault();
        if (notarButton && notarButton.current) {
            notarButton.current.click()!;
        }
    }, []);

    const clickShortExpose = useCallback((e) => {
        e.preventDefault();
        if (shortExposeButton && shortExposeButton.current) {
            shortExposeButton.current.click()!;
        }
    }, []);

    const clickExpose = useCallback((e) => {
        e.preventDefault();
        if (exposeButton && exposeButton.current) {
            exposeButton.current.click()!;
        }
    }, []);

    const handlers = {
        SAVE: (e: any) => saveObject(e),
        SHORTEXPOSE: (e: any) => clickShortExpose(e),
        EXPOSE: (e: any) => clickExpose(e),
        NOTAR: (e: any) => clickNotar(e),
    };

    const deletePicture = (pictureName: string) => {
        if (dataTmp && dataTmp.pictures) {
            let picturesTmp = dataTmp.pictures;
            delete picturesTmp[pictureName];
            setDataTmp({
                ...dataTmp,
                pictures: picturesTmp,
            });
            ri_real_estate_delete_picture(pictureName);
        }
    };

    const deleteObjekt = (objectId: string, title: string) => {
        showConfirmPopup({
            title: `Objekt ${title} (${objectId}) wirklich löschen?`,
            text: 'Löschen ist irreversibel',
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen',
            confirmedFunction: () => {
                ri_real_estate_delete_objekt(objectId);
            },
        });
    };

    const deleteAllStakeholder = (objectId: string) => {
        showConfirmPopup({
            title: `Alle Interessenten wirklich löschen?`,
            text: 'Löschen ist irreversibel',
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen',
            confirmedFunction: () => {
                ri_real_estate_delete_all_stakeholder(objectId);
            },
        });
    };

    const deleteStakeholder = (stakeholder: any) => {
        showConfirmPopup({
            title: `${stakeholder.data.firstName ? stakeholder.data.firstName : ''} ${stakeholder.data.lastName ? stakeholder.data.lastName : ''} wirklich löschen?`,
            text: 'Löschen ist irreversibel',
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen',
            confirmedFunction: () => {
                ri_real_estate_delete_stakeholder(stakeholder.id);
            },
        });
    };

    const sendStakeholderDocument = (objectId: string, stakeholder: any, type: string, dataTmp?: any) => {
        let title = '';
        if (type === 'document') {
            title = 'Mieterselbstauskunft wirklich schicken';
        }
        if (type === 'interest') {
            title = 'Interesse E-Mail wirklich schicken';
        }
        if (type === 'expose') {
            title = 'Exposé Aktualisierung wirklich schicken';
        }

        showConfirmPopup({
            title,
            text: `Es wird mit der Bestätigung direkt eine E-Mail an ${stakeholder.data.firstName ? stakeholder.data.firstName : ''} ${
                stakeholder.data.lastName ? stakeholder.data.lastName : ''
            } verschickt`,
            confirmButtonText: 'Schicken',
            cancelButtonText: 'Abbrechen',
            confirmButtonColor: '#28a745',
            confirmedFunction: () => {
                ri_real_estate_document_stakeholder(stakeholder, objectId, type, dataTmp);
            },
        });
    };

    const copyToClipboard = (inputValue: any) => {
        const el = document.createElement('textarea');
        el.value = inputValue;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const moveObjectElement = (currentKey: string, afterKey: string, obj: any) => {
        let result: any = {};
        let val = obj[currentKey];
        delete obj[currentKey];
        let next = -1;
        let i = 0;
        if (typeof afterKey == 'undefined' || afterKey == null) afterKey = '';
        $.each(obj, function (k, v) {
            if ((afterKey == '' && i == 0) || next == 1) {
                result[currentKey] = val;
                next = 0;
            }
            if (k == afterKey) {
                next = 1;
            }
            result[k] = v;
            ++i;
        });
        if (next == 1) {
            result[currentKey] = val;
        }
        if (next !== -1) return result;
        else return obj;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        // console.log(result.source.index);
        // console.log(result.destination.index);

        let destinationIndex: number = result.destination.index;

        if (result.destination.index > 0 && result.destination.index < result.source.index) {
            destinationIndex = result.destination.index - 1;
        }

        if (dataTmp) {
            setDataTmp({
                ...dataTmp,
                pictures: moveObjectElement(
                    Object.keys(dataTmp.pictures)[result.source.index],
                    result.destination.index === 0 ? '' : Object.keys(dataTmp.pictures)[destinationIndex],
                    dataTmp.pictures
                ),
            });
        }
    };

    const checkIfSavePossible = () => {
        let possible = false;
        if (dataTmp && dataTmp.realEstateType === 'realestates.houseBuy') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.buildingType &&
                dataTmp.livingSpace &&
                dataTmp.livingSpace > 0 &&
                dataTmp.plotArea &&
                dataTmp.plotArea > 0 &&
                dataTmp.numberOfRooms &&
                dataTmp.numberOfRooms > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.constructionYear &&
                dataTmp.constructionYear > 0
            ) {
                if (dataTmp.synchEstate && dataTmp.synchEstate.saveScout && dataTmp.investmentType && dataTmp.netFloorSpace && dataTmp.netFloorSpace > 0) {
                    possible = true;
                }
                if (!dataTmp.synchEstate || !dataTmp.synchEstate.saveScout) {
                    possible = true;
                }
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.houseRent') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.buildingType &&
                dataTmp.livingSpace &&
                dataTmp.livingSpace > 0 &&
                dataTmp.plotArea &&
                dataTmp.plotArea > 0 &&
                dataTmp.numberOfRooms &&
                dataTmp.numberOfRooms > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.baseRent &&
                dataTmp.baseRent > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.apartmentBuy') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.apartmentType &&
                dataTmp.apartmentType.length > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.livingSpace &&
                dataTmp.livingSpace > 0 &&
                dataTmp.numberOfRooms &&
                dataTmp.numberOfRooms > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.apartmentRent') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.apartmentType &&
                dataTmp.apartmentType.length > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.livingSpace &&
                dataTmp.livingSpace > 0 &&
                dataTmp.numberOfRooms &&
                dataTmp.numberOfRooms > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.baseRent &&
                dataTmp.baseRent > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.livingBuySite') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.plotArea &&
                dataTmp.plotArea > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.office') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.officeType &&
                dataTmp.officeType.length > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.netFloorSpace &&
                dataTmp.netFloorSpace > 0 &&
                dataTmp.totalFloorSpace &&
                dataTmp.totalFloorSpace > 0 &&
                dataTmp.marketingType &&
                dataTmp.marketingType.length > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.investment') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.investmentType &&
                dataTmp.investmentType.length > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.netFloorSpace &&
                dataTmp.netFloorSpace > 0 &&
                dataTmp.constructionYear &&
                dataTmp.constructionYear > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.garageBuy') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.garageType &&
                dataTmp.garageType.length > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.garageRent') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.garageType &&
                dataTmp.garageType.length > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.store') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.marketingType &&
                dataTmp.marketingType.length > 0 &&
                dataTmp.netFloorSpace &&
                dataTmp.netFloorSpace > 0 &&
                dataTmp.totalFloorSpace &&
                dataTmp.totalFloorSpace > 0
            ) {
                possible = true;
            }
        }
        if (dataTmp && dataTmp.realEstateType === 'realestates.tradeSite') {
            if (
                dataTmp.title &&
                dataTmp.title.length > 0 &&
                dataTmp.value &&
                dataTmp.value > 0 &&
                dataTmp.street &&
                dataTmp.street.length > 0 &&
                dataTmp.houseNumber &&
                dataTmp.houseNumber.length > 0 &&
                dataTmp.postcode &&
                dataTmp.postcode.length > 0 &&
                dataTmp.city &&
                dataTmp.city.length > 0 &&
                dataTmp.plotArea &&
                dataTmp.plotArea > 0 &&
                dataTmp.courtage &&
                dataTmp.courtage.length > 0 &&
                dataTmp.utilizationTradeSite &&
                dataTmp.utilizationTradeSite.length > 0
            ) {
                possible = true;
            }
        }
        return possible;
    };

    const optionSelect = (label: string, field: string, options: { value: string; label: string }[]) => {
        return (
            <div className="row">
                <div className="form-group col-lg-12">
                    <label>{label}</label>
                    <select
                        className={`form-control ${label.includes('*') && dataTmp && (!dataTmp[field] || dataTmp[field] === '') ? 'border-danger' : null}`}
                        onChange={(e) =>
                            setDataTmp({
                                ...dataTmp,
                                [field]: e.target.value,
                                [`${field}_label`]: label,
                                [`${field}_text`]: e.target.selectedOptions[0].text,
                            })
                        }
                        value={dataTmp && dataTmp[field] ? dataTmp[field] : ''}
                    >
                        <option value=""></option>
                        {options.map((option) => {
                            return <option value={option.value}>{option.label}</option>;
                        })}
                    </select>
                </div>
            </div>
        );
    };

    const inputField = (
        label: string,
        field: string,
        columns: string = '12',
        type: string = 'text',
        rowClasses?: string,
        additionalClasses?: string,
        appendText?: string,
        maxLength?: number,
        prependText?: string
    ) => {
        return (
            <div className={`row ${rowClasses ? rowClasses : ''}`}>
                <div className={`form-group col-lg-${columns}`}>
                    <label>{label}</label>
                    <div className="input-group">
                        {prependText ? (
                            <div className="input-group-prepend">
                                <span className="input-group-text">{prependText}</span>
                            </div>
                        ) : null}
                        <input
                            type={type}
                            className={`form-control ${additionalClasses ? additionalClasses : ''} ${
                                label.includes('*') && dataTmp && (!dataTmp[field] || dataTmp[field].length === 0) ? 'border-danger' : ''
                            }`}
                            placeholder=""
                            value={dataTmp && dataTmp[field] ? (dataTmp[field] === '€' || dataTmp[field] === 'kWh/(m²*a)' ? dataTmp[field].replace('.', ',') : dataTmp[field]) : ''}
                            onChange={(e) => {
                                let value: string | number = e.target.value;
                                if (type === 'number') {
                                    value = e.target.value.replace(',', '.');
                                }
                                setDataTmp({
                                    ...dataTmp,
                                    [field]: type === 'number' && value !== '0' ? parseFloat(value) : value,
                                });
                            }}
                            pattern={type === 'number' ? '[0-9]+([\\.,][0-9]+)?' : ''}
                            maxLength={maxLength ? maxLength : undefined}
                        />
                        {appendText ? (
                            <div className="input-group-append">
                                <span className="input-group-text">{appendText}</span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const switchSelect = (label: string, field: string, columns: string = '12', rowClasses?: string, trueValue: string | boolean = 'YES', falseValue: string | boolean = 'NOT_APPLICABLE') => {
        return (
            <div className={`row ${rowClasses ? rowClasses : ''}`}>
                <div className={`form-group col-lg-${columns} ml-3`}>
                    <label></label>
                    {/* <div className="input-group">
                        <div className="icheck-success d-inline text-center">
                            <input
                                type="checkbox"
                                id={field}
                                onChange={(e) =>
                                    setDataTmp({
                                        ...dataTmp,
                                        [field]: document.querySelector(`#${field}:checked`) ? trueValue : falseValue,
                                    })
                                }
                                checked={dataTmp && dataTmp[field] === trueValue ? true : false}
                            />
                            <label htmlFor={field}>{label}</label>
                            
                        </div>
                    </div> */}
                    <div className="input-group">
                        <Switch
                            onChange={(e) =>
                                setDataTmp({
                                    ...dataTmp,
                                    [field]: e ? trueValue : falseValue,
                                    [`${field}_label`]: label,
                                    [`${field}_boolean`]: e,
                                })
                            }
                            className="align-middle"
                            checked={dataTmp && dataTmp[field] === trueValue ? true : false}
                            uncheckedIcon={false}
                        />
                        <label className="align-middle mt-1 ml-2">{label}</label>
                    </div>
                </div>
            </div>
        );
    };

    const immobilienStatus = () => {
        return optionSelect('Immobilien Status', 'realEstateStatus', [
            { value: 'RESERVED', label: 'reserviert' },
            { value: 'SOLD', label: 'verkauft' },
            { value: 'RENTED', label: 'vermietet' },
            { value: 'ARCHIVED', label: 'archiviert' },
        ]);
    };

    const hausTyp = () => {
        return optionSelect('Haustyp*', 'buildingType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'SINGLE_FAMILY_HOUSE', label: 'Einfamilienhaus' },
            { value: 'MID_TERRACE_HOUSE', label: 'Reihenmittelhaus' },
            { value: 'END_TERRACE_HOUSE', label: 'Reiheneckhaus' },
            { value: 'MULTI_FAMILY_HOUSE', label: 'Mehrfamilienhaus' },
            { value: 'BUNGALOW', label: 'Bungalow' },
            { value: 'FARMHOUSE', label: 'Bauernhaus' },
            { value: 'SEMIDETACHED_HOUSE', label: 'Doppelhaushälfte' },
            { value: 'VILLA', label: 'Villa' },
            { value: 'CASTLE_MANOR_HOUSE', label: 'Burg / Schloss' },
            { value: 'SPECIAL_REAL_ESTATE', label: 'besondere Immobilie' },
            { value: 'OTHER', label: 'Sonstiges' },
        ]);
    };

    const objektZustand = () => {
        return optionSelect('Objektzustand', 'condition', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'FIRST_TIME_USE', label: 'Erstbezug' },
            { value: 'FIRST_TIME_USE_AFTER_REFURBISHMENT', label: 'Erstbezug nach Sanierung' },
            { value: 'MINT_CONDITION', label: 'Neuwertig' },
            { value: 'REFURBISHED', label: 'Saniert' },
            { value: 'MODERNIZED', label: 'Modernisiert' },
            { value: 'FULLY_RENOVATED', label: 'Vollständig Renoviert' },
            { value: 'WELL_KEPT', label: 'Gepflegt' },
            { value: 'NEED_OF_RENOVATION', label: 'Renovierungsbedürftig' },
            { value: 'NEGOTIABLE', label: 'Nach Vereinbarung' },
            { value: 'RIPE_FOR_DEMOLITION', label: 'Abbruchreif' },
        ]);
    };

    const bauphase = () => {
        return optionSelect('Bauphase', 'constructionPhase', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'PROJECTED', label: 'Haus in Planung' },
            { value: 'UNDER_CONSTRUCTION', label: 'Haus im Bau' },
            { value: 'COMPLETED', label: 'Haus fertig gestellt' },
        ]);
    };

    const qualitaetDerAusstattung = () => {
        return optionSelect('Qualität der Ausstattung', 'interiorQuality', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'LUXURY', label: 'luxus' },
            { value: 'SOPHISTICATED', label: 'gehoben' },
            { value: 'NORMAL', label: 'normal' },
            { value: 'SIMPLE', label: 'einfach' },
        ]);
    };

    const heizungsartIS = () => {
        return optionSelect('Heizungsart IS24', 'heatingTypeEnev2014', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'SELF_CONTAINED_CENTRAL_HEATING', label: 'Etagenheizung' },
            { value: 'STOVE_HEATING', label: 'Ofenheizung' },
            { value: 'CENTRAL_HEATING', label: 'Zentralheizung' },
            { value: 'COMBINED_HEAT_AND_POWER_PLANT', label: 'Blockheizkraftwerk' },
            { value: 'ELECTRIC_HEATING', label: 'Elektro-Heizung' },
            { value: 'DISTRICT_HEATING', label: 'Fernwärme' },
            { value: 'FLOOR_HEATING', label: 'Fußbodenheizung' },
            { value: 'GAS_HEATING', label: 'Gas-Heizung' },
            { value: 'WOOD_PELLET_HEATING', label: 'Holz-Pelletheizung' },
            { value: 'NIGHT_STORAGE_HEATER', label: 'Nachtspeicherofen' },
            { value: 'OIL_HEATING', label: 'Öl-Heizung' },
            { value: 'SOLAR_HEATING', label: 'Solar-Heizung' },
            { value: 'HEAT_PUMP', label: 'Wärmepumpe' },
        ]);
    };

    const heizungsartOI = () => {
        return optionSelect('Heizungsart openImmo', 'heatingTypeOpenImmo', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'FERN', label: 'Fernwärme' },
            { value: 'ETAGE', label: 'Etagenheizung' },
            { value: 'ZENTRAL', label: 'Zentralheizung' },
            { value: 'OFEN', label: 'Ofen' },
            { value: 'FUSSBODEN', label: 'Fußbodenheizung' },
        ]);
    };

    const befeuerungsarten = () => {
        return optionSelect('Befeuerungsarten', 'firingType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'GEOTHERMAL', label: 'Erdwärme' },
            { value: 'SOLAR_HEATING', label: 'Solarheizung' },
            { value: 'PELLET_HEATING', label: 'Pelletheizung' },
            { value: 'GAS', label: 'Gas' },
            { value: 'OIL', label: 'Öl' },
            { value: 'DISTRICT_HEATING', label: 'Fernwärme' },
            { value: 'ELECTRICITY', label: 'Strom' },
            { value: 'COAL', label: 'Kohle' },
        ]);
    };

    const befeuerungsartenNeu = () => {
        return optionSelect('Befeuerungsarten (neu)', 'energySourceEnev2014', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'BIO_ENERGY', label: 'Bioenergie' },
            { value: 'WIND_ENERGY', label: 'Windenergie' },
            { value: 'HYDRO_ENERGY', label: 'Wasserenergie' },
            { value: 'ENVIRONMENTAL_THERMAL_ENERGY', label: 'Umweltwärme' },
            { value: 'COMBINED_HEAT_AND_POWER_FOSSIL_FUELS', label: 'KWK fossil' },
            { value: 'COMBINED_HEAT_AND_POWER_RENEWABLE_ENERGY', label: 'KWK erneuerbar' },
            { value: 'COMBINED_HEAT_AND_POWER_REGENERATIVE_ENERGY', label: 'KWK regenerativ' },
            { value: 'COMBINED_HEAT_AND_POWER_BIO_ENERGY', label: 'KWK Bio' },
        ]);
    };

    const energieausweisVerfuegbarkeit = () => {
        return optionSelect('Energieausweis-Verfügbarkeit', 'energyCertificateAvailability', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'AVAILABLE', label: 'Energieausweis liegt für das Gebäude vor' },
            { value: 'NOT_AVAILABLE_YET', label: 'Energieausweis liegt zur Besichtigung vor' },
            { value: 'NOT_REQUIRED', label: 'Dieses Gebäude unterliegt nicht den GEG-Anforderungen' },
        ]);
    };

    const energieausweisDatum = () => {
        return optionSelect('Energieausweis-Erstelldatum', 'energyCertificateCreationDate', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'BEFORE_01_MAY_2014', label: 'bis 30. April 2014' },
            { value: 'FROM_01_MAY_2014', label: 'ab 1. Mai 2014' },
        ]);
    };

    const parkplatz = () => {
        return optionSelect('Parkplatz', 'parkingSpaceType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'GARAGE', label: 'Garage' },
            { value: 'OUTSIDE', label: 'Außenstellplatz' },
            { value: 'CARPORT', label: 'Carport' },
            { value: 'DUPLEX', label: 'Duplex' },
            { value: 'CAR_PARK', label: 'Parkhaus' },
            { value: 'UNDERGROUND_GARAGE', label: 'Tiefgarage' },
        ]);
    };

    const energieausweistyp = () => {
        return optionSelect('Energieausweistyp', 'buildingEnergyRatingType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'ENERGY_REQUIRED', label: 'Endenergiebedarf' },
            { value: 'ENERGY_CONSUMPTION', label: 'Energieverbrauchskennwert' },
        ]);
    };

    const anlagetyp = () => {
        return optionSelect('Anlagetyp*', 'investmentType', [
            { value: 'SINGLE_FAMILY_HOUSE', label: 'Einfamilienhaus' },
            { value: 'MULTI_FAMILY_HOUSE', label: 'Mehrfamilienhaus' },
            { value: 'FREEHOLD_FLAT', label: 'Eigentumswohnung' },
            { value: 'SHOPPING_CENTRE', label: 'Einkaufszentrum' },
            { value: 'RESTAURANT', label: 'Restaurant' },
            { value: 'HOTEL', label: 'Hotel' },
            { value: 'LEISURE_FACILITY', label: 'Freizeitanlage' },
            { value: 'COMMERCIAL_UNIT', label: 'Gewerbeeinheit' },
            { value: 'OFFICE_BUILDING', label: 'Bürogebäude' },
            { value: 'COMMERCIAL_BUILDING', label: 'Geschäftsgebäude' },
            { value: 'COMMERCIAL_PROPERTY', label: 'Geschäftsgrundstück' },
            { value: 'HALL_STORAGE', label: 'Halle / Lager' },
            { value: 'INDUSTRIAL_PROPERTY', label: 'Industrieanwesen' },
            { value: 'SHOP_SALES_FLOOR', label: 'Laden / Verkaufsfläche' },
            { value: 'SERVICE_CENTRE', label: 'Servicecenter' },
            { value: 'OTHER', label: 'Sonstige' },
            { value: 'SUPERMARKET', label: 'Supermarkt' },
            { value: 'LIVING_BUSINESS_HOUSE', label: 'Wohn- / Geschäftshaus' },
            { value: 'HOUSING_ESTATE', label: 'Wohnanlage' },
        ]);
    };

    const haustiereErlaubt = () => {
        return optionSelect('Haustiere erlaubt', 'petsAllowed', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'YES', label: 'Ja' },
            { value: 'NO', label: 'Nein' },
            { value: 'NEGOTIABLE', label: 'verhandelbar' },
        ]);
    };

    const wohnungskategorie = () => {
        return optionSelect('Wohnungskategorie*', 'apartmentType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'ROOF_STOREY', label: 'Dachgeschoss' },
            { value: 'LOFT', label: 'Loft' },
            { value: 'MAISONETTE', label: 'Maisonette' },
            { value: 'PENTHOUSE', label: 'Penthouse' },
            { value: 'TERRACED_FLAT', label: 'Terrassenwohnung' },
            { value: 'GROUND_FLOOR', label: 'Erdgeschosswohnung' },
            { value: 'APARTMENT', label: 'Etagenwohnung' },
            { value: 'RAISED_GROUND_FLOOR', label: 'Hochparterre' },
            { value: 'HALF_BASEMENT', label: 'Souterrain' },
            { value: 'OTHER', label: 'Sonstige' },
        ]);
    };

    const empfohleneNutzungsarten = () => {
        return optionSelect('Empfohlene Nutzungsarten', 'recommendedUseTypes', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'FUTURE_DEVELOPMENT_LAND', label: 'Bauerwartungsland' },
            { value: 'TWINHOUSE', label: 'Doppelhaus' },
            { value: 'SINGLE_FAMILY_HOUSE', label: 'Einfamilienhaus' },
            { value: 'GARAGE', label: 'Garagen' },
            { value: 'GARDEN', label: 'Garten' },
            { value: 'NO_DEVELOPMENT', label: 'keine Bebauung' },
            { value: 'APARTMENT_BUILDING', label: 'Mehrfamilienhaus' },
            { value: 'ORCHARD', label: 'Obstpflanzung' },
            { value: 'TERRACE_HOUSE', label: 'Reihenhaus' },
            { value: 'PARKING_SPACE', label: 'Stellplätze' },
            { value: 'VILLA', label: 'Villa' },
            { value: 'FORREST', label: 'Wald' },
        ]);
    };

    const erschliessungszustand = () => {
        return optionSelect('Erschliessungszustand', 'siteDevelopmentType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'DEVELOPED', label: 'erschlossen' },
            { value: 'DEVELOPED_PARTIALLY', label: 'teilerschlossen' },
            { value: 'NOT_DEVELOPED', label: 'unerschlossen' },
        ]);
    };

    const bebaubarNach = () => {
        return optionSelect('Bebaubar nach', 'siteConstructibleType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'CONSTRUCTIONPLAN', label: 'Bebauung nach Bebauungsplan' },
            { value: 'NEIGHBOURCONSTRUCTION', label: 'Nachbarbebauung' },
            { value: 'EXTERNALAREA', label: 'Außengebiet' },
        ]);
    };

    const buerotyp = () => {
        return optionSelect('Bürotyp*', 'officeType', [
            { value: 'LOFT', label: 'Loft' },
            { value: 'STUDIO', label: 'Atelier' },
            { value: 'OFFICE', label: 'Büro' },
            { value: 'OFFICE_FLOOR', label: 'Büro / Etage' },
            { value: 'OFFICE_BUILDING', label: 'Bürohaus' },
            { value: 'OFFICE_CENTRE', label: 'Bürozentrum' },
            { value: 'OFFICE_STORAGE_BUILDING', label: 'Büro- und Lagergebäude' },
            { value: 'SURGERY', label: 'Praxis' },
            { value: 'SURGERY_FLOOR', label: 'Praxis-Etage' },
            { value: 'SURGERY_BUILDING', label: 'Praxis-Haus' },
            { value: 'COMMERCIAL_CENTRE', label: 'Gewerbezentrum' },
            { value: 'LIVING_AND_COMMERCIAL_BUILDING', label: 'Wohn- und Geschäftsgebäude' },
            { value: 'OFFICE_AND_COMMERCIAL_BUILDING', label: 'Büro- und Geschäftsgebäude' },
        ]);
    };

    const bodenbelag = () => {
        return optionSelect('Bodenbelag', 'flooringType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'CONCRETE', label: 'Beton' },
            { value: 'EPOXY_RESIN', label: 'Epoxidharz' },
            { value: 'TILES', label: 'Fliesen' },
            { value: 'PLANKS', label: 'Dielen' },
            { value: 'LAMINATE', label: 'Laminat' },
            { value: 'PARQUET', label: 'Parkett' },
            { value: 'PVC', label: 'PVC' },
            { value: 'CARPET', label: 'Teppichboden' },
            { value: 'ANTISTATIC_FLOOR', label: 'Teppichboden Antistatisch' },
            { value: 'OFFICE_CARPET', label: 'Teppichfliesen Stuhlrollenfest' },
            { value: 'STONE', label: 'Stein' },
            { value: 'CUSTOMIZABLE', label: 'nach Wunsch' },
            { value: 'WITHOUT', label: 'ohne Bodenbelag' },
        ]);
    };

    const datenverkabelung = () => {
        return optionSelect('Datenverkabelung', 'lanCables', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'YES', label: 'Ja' },
            { value: 'NO', label: 'Nein' },
            { value: 'BY_APPOINTMENT', label: 'Auf Anfrage' },
        ]);
    };

    const klimaanlage = () => {
        return optionSelect('Klimaanlage', 'airConditioning', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'YES', label: 'Ja' },
            { value: 'NO', label: 'Nein' },
            { value: 'BY_APPOINTMENT', label: 'Auf Anfrage' },
        ]);
    };

    const preisFlaecheOderZeitraum = () => {
        return optionSelect('Preis gilt für Fläche / Zeitraum*', 'marketingType', [
            { value: 'RENT', label: 'Zeitraum' },
            { value: 'RENT_PER_SQM', label: 'Fläche' },
            { value: 'NO', label: 'Nein' },
            { value: 'BY_APPOINTMENT', label: 'Auf Anfrage' },
        ]);
    };

    const garagentyp = () => {
        return optionSelect('Garagentyp*', 'garageType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'GARAGE', label: 'Garage' },
            { value: 'STREET_PARKING', label: 'Außenstellplatz' },
            { value: 'CARPORT', label: 'Carport' },
            { value: 'DUPLEX', label: 'Duplex' },
            { value: 'CAR_PARK', label: 'Parkhaus' },
            { value: 'UNDERGROUND_GARAGE', label: 'Tiefgarage' },
        ]);
    };

    const ladentyp = () => {
        return optionSelect('Ladentyp', 'storeType', [
            { value: 'SHOWROOM_SPACE', label: 'Ausstellungsfläche' },
            { value: 'SHOPPING_CENTRE', label: 'Einkaufszentrum' },
            { value: 'FACTORY_OUTLET', label: 'Factory-Outlet' },
            { value: 'DEPARTMENT_STORE', label: 'Kaufhaus' },
            { value: 'KIOSK', label: 'Kiosk' },
            { value: 'STORE', label: 'Laden' },
            { value: 'SELF_SERVICE_MARKET', label: 'SB-Markt' },
            { value: 'SALES_AREA', label: 'Verkaufsfläche' },
            { value: 'SALES_HALL', label: 'Verkaufshalle' },
        ]);
    };

    const lageart = () => {
        return optionSelect('Lageart', 'locationClassificationType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'CLASSIFICATION_A', label: 'A-Lage' },
            { value: 'CLASSIFICATION_B', label: 'B-Lage' },
            { value: 'SHOPPING_CENTRE', label: 'Einkaufszentrum' },
        ]);
    };

    const zulieferungsart = () => {
        return optionSelect('Zulieferungsart', 'supplyType', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'DIRECT_APPROACH', label: 'direkter Zugang' },
            { value: 'NO_DIRECT_APPROACH', label: 'keine direkte Anfahrt' },
            { value: 'CAR_APPROACH', label: 'PKW-Zufahrt' },
            { value: 'APPROACH_TO_THE_FRONT', label: 'von vorn' },
            { value: 'APPROACH_TO_THE_BACK', label: 'von hinten' },
            { value: 'FULL_TIME', label: 'ganztätig' },
            { value: 'FORENOON', label: 'vormittags' },
        ]);
    };

    const empfohleneNutzungsartenGewerbegrundstueck = () => {
        return optionSelect('Empfohlene Nutzungsarten', 'recommendedUseTypes', [
            { value: 'NO_INFORMATION', label: 'keine Angabe' },
            { value: 'FARMLAND', label: 'Ackerland' },
            { value: 'FUTURE_DEVELOPMENT_LAND', label: 'Bauerwartungsland' },
            { value: 'MOORAGE', label: 'Bootsstaende' },
            { value: 'OFFICE', label: 'Buero' },
            { value: 'CAMPING', label: 'Camping' },
            { value: 'BIG_STORE', label: 'Einzelhandel (groß)' },
            { value: 'LITTLE_STORE', label: 'Einzelhandel (klein)' },
            { value: 'GARAGE', label: 'Garagen' },
            { value: 'GARDEN', label: 'Garten' },
            { value: 'GASTRONOMY', label: 'Gastronomie' },
            { value: 'BUSINESS', label: 'Gewerbe' },
            { value: 'HOTEL', label: 'Hotel' },
            { value: 'INDUSTRY', label: 'Industrie' },
            { value: 'NO_DEVELOPMENT', label: 'keine Bebauung' },
            { value: 'SMALL_BUSINESS', label: 'Kleingewerbe' },
            { value: 'STOCK', label: 'Lager' },
            { value: 'ORCHARD', label: 'Obstgarten' },
            { value: 'CAR_PARK', label: 'Parkhaus' },
            { value: 'PRODUCTION', label: 'Produktion' },
            { value: 'PARKING_SPACE', label: 'Stellplaetze' },
            { value: 'FORREST', label: 'Wald' },
        ]);
    };

    const grundstueckskategorie = () => {
        return optionSelect('Grundstückskategorie*', 'utilizationTradeSite', [
            { value: 'LEISURE', label: 'Freizeit' },
            { value: 'AGRICULTURE_FORESTRY', label: 'Land- und Forstwirtschaft' },
            { value: 'TRADE', label: 'Gewerbe' },
        ]);
    };

    const dateToGermanDate = (appointment: any) => {
        const numberOfDotsA = appointment.split('.').length;
        const numberOfCommasA = appointment.split(',').length;
        if (numberOfDotsA === 3 && numberOfCommasA === 2) {
            const dateFragments = appointment.split(',')[0].split('.');
            try {
                appointment =
                    // @ts-ignore
                    new Intl.DateTimeFormat('de-de', { dateStyle: 'long', timeStyle: 'short' }).format(
                        new Date(`${dateFragments[1]}.${dateFragments[0]}.${dateFragments[2]},${appointment.split(',')[1]}`).getTime()
                    ) + ' Uhr';
            } catch (e: any) {}
        }
        return appointment;
    };

    const checkIfDelayed = (appointment: any, appointmentDelayDays: any) => {
        let isDelayed = false;
        if (appointment) {
            const numberOfDotsA = appointment.split('.').length;
            const numberOfCommasA = appointment.split(',').length;
            let isDate = false;
            if (numberOfDotsA === 3 && numberOfCommasA === 2) {
                const dateFragments = appointment.split(',')[0].split('.');
                try {
                    appointment = new Date(`${dateFragments[1]}.${dateFragments[0]}.${dateFragments[2]},${appointment.split(',')[1]}`);
                    isDate = true;
                } catch (e: any) {}
            }
            if (isDate) {
                const today: any = new Date();
                const diffTime = Math.abs(today - appointment);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if (diffDays >= appointmentDelayDays) {
                    isDelayed = true;
                }
            }
        }

        return isDelayed;
    };

    const stakeholderCard = (stakeholder: any) => {
        return (
            <div
                className={`card  card-outline collapsed-card ${!stakeholder.status || stakeholder.status === 'EXPOSE' || stakeholder.status === 'AUSGESCHIEDEN' ? 'card-secondary' : ''}${
                    stakeholder.status && stakeholder.status === 'TERMIN' ? 'card-info' : ''
                }${stakeholder.status && stakeholder.status === 'AUSWAHL' ? 'card-success' : ''}`}
            >
                <div className="card-header" style={{ cursor: 'pointer' }} data-card-widget="collapse">
                    <p className="card-title">
                        <span className="font-weight-bold ml-2  align-middle">
                            {stakeholder.data.firstName} {stakeholder.data.lastName}
                        </span>
                    </p>
                    <div className="card-tools m-0">
                        {stakeholder.data && stakeholder.data.noUpdateEmail ? (
                            <button type="button" className={`btn btn-sm btn-tool-dark btn-secondary mr-2`} disabled>
                                <i className="fas fa-bell-slash" />
                            </button>
                        ) : null}
                        {(stakeholder.status === 'EXPOSE' && checkIfDelayed(stakeholder.data.requestReceivedAt, dataTmp && dataTmp.stakeholderDelayDays ? dataTmp.stakeholderDelayDays : 10)) ||
                        (stakeholder.status === 'TERMIN' && checkIfDelayed(stakeholder.data.appointment, dataTmp && dataTmp.stakeholderDelayDays ? dataTmp.stakeholderDelayDays : 10)) ? (
                            <button type="button" className={`btn btn-sm btn-tool-dark btn-${stakeholder.data.interestEmailSent ? 'danger' : 'warning'}`} data-card-widget="collapse">
                                <i className="fas fa-clock" />
                            </button>
                        ) : (
                            ''
                        )}

                        <button type="button" className="btn btn-sm btn-tool-dark" data-card-widget="collapse">
                            <i className="fas fa-plus" />
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    {editStakeholder && editStakeholder.id && editStakeholder.id === stakeholder.id ? (
                        <>
                            <div className="text-center">
                                <div className="input-group mb-3">
                                    <textarea
                                        className={`form-control control-border`}
                                        rows={7}
                                        placeholder="Kommentar"
                                        value={editStakeholder && editStakeholder.data.agentComment ? editStakeholder.data.agentComment : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.agentComment = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="Anfrage erhalten am (z.B. 03.03.2022, 09:00)"
                                        value={editStakeholder && editStakeholder.data.requestReceivedAt ? editStakeholder.data.requestReceivedAt : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.requestReceivedAt = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="mt-2 input-group mb-3">
                                    <label></label>
                                    <div className="input-group">
                                        <Switch
                                            onChange={(e) => {
                                                let data = { ...editStakeholder.data };
                                                data.noUpdateEmail = e;
                                                setEditStakeholder({
                                                    ...editStakeholder,
                                                    data,
                                                });
                                            }}
                                            className="align-middle"
                                            checked={editStakeholder && editStakeholder.data.noUpdateEmail ? editStakeholder.data.noUpdateEmail : false}
                                            uncheckedIcon={false}
                                        />
                                        <label className="align-middle mt-1 ml-2">Keine Exposé Änderung schicken</label>
                                    </div>
                                </div>
                                <div className="mt-2 input-group mb-3">
                                    <label></label>
                                    <div className="input-group">
                                        <Switch
                                            onChange={(e) => {
                                                let data = { ...editStakeholder.data };
                                                data.renterDocumentSent = e;
                                                setEditStakeholder({
                                                    ...editStakeholder,
                                                    data,
                                                });
                                            }}
                                            className="align-middle"
                                            checked={editStakeholder && editStakeholder.data.renterDocumentSent ? editStakeholder.data.renterDocumentSent : false}
                                            uncheckedIcon={false}
                                        />
                                        <label className="align-middle mt-1 ml-2">Mieterselbstauskunft verschickt</label>
                                    </div>
                                </div>
                                <div className="mt-2 input-group mb-3">
                                    <label></label>
                                    <div className="input-group">
                                        <Switch
                                            onChange={(e) => {
                                                let data = { ...editStakeholder.data };
                                                data.interestEmailSent = e;
                                                setEditStakeholder({
                                                    ...editStakeholder,
                                                    data,
                                                });
                                            }}
                                            className="align-middle"
                                            checked={editStakeholder && editStakeholder.data.interestEmailSent ? editStakeholder.data.interestEmailSent : false}
                                            uncheckedIcon={false}
                                        />
                                        <label className="align-middle mt-1 ml-2">Interesse E-Mail verschickt</label>
                                    </div>
                                </div>
                                <div className="mt-2 input-group mb-3">
                                    <select
                                        className="form-control"
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.title = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                        value={editStakeholder && editStakeholder.data.title ? editStakeholder.data.title : 'Herr'}
                                    >
                                        <option value="Herr">Herr</option>
                                        <option value="Frau">Frau</option>
                                        <option value="Familie">Familie</option>
                                    </select>
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="Vorname"
                                        value={editStakeholder && editStakeholder.data.firstName ? editStakeholder.data.firstName : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.firstName = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="Nachname"
                                        value={editStakeholder && editStakeholder.data.lastName ? editStakeholder.data.lastName : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.lastName = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="Telefon"
                                        value={editStakeholder && editStakeholder.data.telephone ? editStakeholder.data.telephone : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.telephone = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="E-Mail"
                                        value={editStakeholder && editStakeholder.email ? editStakeholder.email : ''}
                                        onChange={(e) =>
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                email: e.target.value,
                                            })
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <textarea
                                        className={`form-control control-border`}
                                        rows={7}
                                        placeholder="Anfrage-Text"
                                        value={editStakeholder && editStakeholder.data.requestText ? editStakeholder.data.requestText.replace(/<\/?[^>]+(>|$)/g, '') : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.requestText = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="Straße"
                                        value={editStakeholder && editStakeholder.data.street ? editStakeholder.data.street : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.street = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="PLZ und Ort"
                                        value={editStakeholder && editStakeholder.data.city ? editStakeholder.data.city : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.city = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className={`form-control control-border`}
                                        placeholder="Besichtigungstermin (z.B. 03.03.2022, 09:00)"
                                        value={editStakeholder && editStakeholder.data.appointment ? editStakeholder.data.appointment : ''}
                                        onChange={(e) => {
                                            let data = { ...editStakeholder.data };
                                            data.appointment = e.target.value;
                                            setEditStakeholder({
                                                ...editStakeholder,
                                                data,
                                            });
                                        }}
                                    />
                                </div>
                                {stakeholder.status && stakeholder.status === 'AUSGESCHIEDEN' ? (
                                    <div className="input-group mb-3">
                                        <textarea
                                            className={`form-control control-border`}
                                            rows={7}
                                            placeholder="Absagegrund"
                                            value={editStakeholder && editStakeholder.data.declineReason ? editStakeholder.data.declineReason : ''}
                                            onChange={(e) => {
                                                let data = { ...editStakeholder.data };
                                                data.declineReason = e.target.value;
                                                setEditStakeholder({
                                                    ...editStakeholder,
                                                    data,
                                                });
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row mb-4">
                                <section className="col-12">
                                    <button className={`btn btn-info btn-block`} onClick={(e) => window!.open(`https://mail.google.com/mail/u/0/#search/${stakeholder.email}`, '_blank')!.focus()}>
                                        E-Mail Verlauf anzeigen
                                    </button>
                                </section>
                            </div>
                            <div className="row mb-4">
                                <section className="col-12">
                                    <button
                                        className={`btn btn-primary btn-block`}
                                        onClick={(e) =>
                                            ri_real_estate_get_stakeholder_confirmation_document(
                                                stakeholder.id,
                                                objekt.objectId,
                                                `${dataTmp && (dataTmp.realEstateType === 'realestates.houseRent' || dataTmp.realEstateType === 'realestates.apartmentRent') ? 'rent' : 'buy'}`
                                            )
                                        }
                                    >
                                        Objektnachweis herunterladen
                                    </button>
                                </section>
                            </div>
                            {stakeholder.status &&
                            stakeholder.status !== 'AUSGESCHIEDEN' &&
                            dataTmp &&
                            (dataTmp.realEstateType === 'realestates.houseRent' || dataTmp.realEstateType === 'realestates.apartmentRent') ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <button
                                            className={`btn btn-primary btn-block`}
                                            onClick={(e) => sendStakeholderDocument(objekt.objectId, stakeholder, 'document')}
                                            disabled={stakeholder.data.renterDocumentSent ? stakeholder.data.renterDocumentSent : false}
                                        >
                                            {stakeholder.data.renterDocumentSent ? 'Mieterselbstauskunft bereits verschickt' : 'Mieterselbstauskunft schicken'}
                                        </button>
                                    </section>
                                </div>
                            ) : null}
                            <div className="row mb-4">
                                <section className="col-12">
                                    <button
                                        className={`btn btn-primary btn-block`}
                                        onClick={(e) => sendStakeholderDocument(objekt.objectId, stakeholder, 'interest')}
                                        disabled={stakeholder.data.interestEmailSent ? stakeholder.data.interestEmailSent : false}
                                    >
                                        {stakeholder.data.interestEmailSent ? 'Interesse E-Mail bereits verschickt' : 'Interesse E-Mail schicken'}
                                    </button>
                                </section>
                            </div>
                            {stakeholder.data && !stakeholder.data.noUpdateEmail ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <button className={`btn btn-primary btn-block`} onClick={(e) => sendStakeholderDocument(objekt.objectId, stakeholder, 'expose', dataTmp)}>
                                            Exposé Aktualisierung schicken{' '}
                                            {stakeholder.data && stakeholder.data.noUpdateEmailCount ? ` (bereits ${stakeholder.data.noUpdateEmailCount} mal verschickt)` : null}
                                        </button>
                                    </section>
                                </div>
                            ) : null}

                            {stakeholder.data.agentComment && stakeholder.data.agentComment.length > 0 ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className="card card-success mb-1 collapsed-card">
                                            <div className="btn btn-outline-secondary btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                                Kommentar
                                            </div>
                                            <div className="card-body box-profile">
                                                <p className="font-weight-normal mt-1">{stakeholder.data.agentComment}</p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            {stakeholder.data.declineReason && stakeholder.data.declineReason.length > 0 ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className="card card-success mb-1 collapsed-card">
                                            <div className="btn btn-outline-secondary btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                                Absagegrund
                                            </div>
                                            <div className="card-body box-profile">
                                                <p className="font-weight-normal mt-1">{stakeholder.data.declineReason}</p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            <div className="row mb-4">
                                <section className="col-12">
                                    <div className="card card-success mb-1 collapsed-card">
                                        <div className="btn btn-outline-secondary btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                            Anfragetext
                                        </div>
                                        <div className="card-body box-profile">
                                            <p className="font-weight-normal mt-1">{stakeholder.data.requestText ? stakeholder.data.requestText.replace(/<\/?[^>]+(>|$)/g, '') : ''}</p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {stakeholder.data.requestTextTranslated ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className="card card-success mb-1 collapsed-card">
                                            <div className="btn btn-outline-secondary btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                                übersetzter Anfragetext
                                            </div>
                                            <div className="card-body box-profile">
                                                <p className="font-weight-normal mt-1">
                                                    {stakeholder.data.requestTextTranslated ? stakeholder.data.requestTextTranslated.replace(/<\/?[^>]+(>|$)/g, '') : ''}
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            {/* {stakeholder.data.requestTextTranslated ? (
                        <div className="row mb-4">
                            <section className="col-12">
                                <p className="font-weight-normal mt-1">{stakeholder.data.requestTextTranslated}</p>
                            </section>
                        </div>
                    ) : null} */}
                            {stakeholder.data.requestReceivedAt ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className="info-box shadow-sm">
                                            <span className="info-box-icon bg-secondary">
                                                <i className="far fa-envelope-open" />
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Anfrage erhalten am</span>
                                                <span className="info-box-number">{dateToGermanDate(stakeholder.data.requestReceivedAt)}</span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            {stakeholder.data.city ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className="info-box shadow-sm">
                                            <span className="info-box-icon bg-secondary">
                                                <i className="far fa-flag" />
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Wohnort</span>
                                                <span className="info-box-number">
                                                    {stakeholder.data.street}, {stakeholder.data.city}
                                                </span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            {stakeholder.email ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className={`info-box shadow-sm text-dark`}>
                                            <span className="info-box-icon bg-secondary">
                                                <i className="far fa-envelope" />
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">E-Mail</span>
                                                <span className="info-box-number">{stakeholder.email}</span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            {stakeholder.data.telephone ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <a className={`info-box shadow-sm text-dark`} href={`tel:${stakeholder.data.telephone}`} rel="noreferrer" style={{ cursor: 'pointer' }}>
                                            <span className="info-box-icon bg-success">
                                                <i className="fa fa-phone" />
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Telefon</span>
                                                <span className="info-box-number">{stakeholder.data.telephone}</span>
                                            </div>
                                        </a>
                                    </section>
                                </div>
                            ) : null}
                            {stakeholder.data.appointment ? (
                                <div className="row mb-4">
                                    <section className="col-12">
                                        <div className="info-box shadow-sm">
                                            <span className="info-box-icon bg-secondary">
                                                <i className="far fa-clock" />
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">Besichtigungstermin</span>
                                                <span className="info-box-number">{dateToGermanDate(stakeholder.data.appointment)}</span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ) : null}
                            <button className={`btn btn-sm btn-secondary btn-block mb-2`} onClick={(e) => setEditStakeholder(stakeholder)}>
                                Interessent Bearbeiten
                            </button>

                            <button className={`btn btn-sm btn-danger btn-block`} onClick={(e) => deleteStakeholder(stakeholder)}>
                                Interessent Löschen
                            </button>
                        </>
                    )}
                </div>
                <div className="card-footer">
                    {editStakeholder && editStakeholder.id && editStakeholder.id === stakeholder.id ? (
                        <>
                            <button
                                className="btn btn-sm btn-primary btn-block mb-2"
                                onClick={(e) => {
                                    ri_real_estate_update_stakeholder(editStakeholder.id, editStakeholder.status, editStakeholder.data);
                                    setEditStakeholder({});
                                }}
                                disabled={!editStakeholder || (editStakeholder && (!editStakeholder.email || !editStakeholder.data.lastName))}
                            >
                                <b>Speichern</b>
                            </button>
                            <button
                                className="btn btn-sm btn-secondary btn-block"
                                onClick={(e) => {
                                    setEditStakeholder({});
                                }}
                                disabled={!editStakeholder || (editStakeholder && (!editStakeholder.email || !editStakeholder.data.lastName))}
                            >
                                <b>Abbrechen</b>
                            </button>
                        </>
                    ) : (
                        <>
                            {!stakeholder.status || stakeholder.status === 'EXPOSE' ? (
                                <>
                                    <div className="input-group mb-2">
                                        <input type="text" className={`form-control`} placeholder="Besichtigungstermin (z.B. 03.03.2022, 09:00)" id={`termin_${stakeholder.id}`} />
                                    </div>
                                    <button
                                        className={`btn btn-sm btn-info btn-block mb-2`}
                                        onClick={(e) =>
                                            ri_real_estate_update_stakeholder(stakeholder.id, 'TERMIN', {
                                                appointment: (document.getElementById(`termin_${stakeholder.id}`) as HTMLInputElement).value,
                                            })
                                        }
                                    >
                                        Termin vereinbart
                                    </button>
                                    <textarea className={`form-control control-border mb-2`} rows={3} placeholder="Absagegrund" id={`absage_${stakeholder.id}`} />
                                    <button
                                        className={`btn btn-sm btn-secondary btn-block`}
                                        onClick={(e) =>
                                            ri_real_estate_update_stakeholder(stakeholder.id, 'AUSGESCHIEDEN', {
                                                declineReason: (document.getElementById(`absage_${stakeholder.id}`) as HTMLInputElement).value,
                                            })
                                        }
                                    >
                                        Abgesagt / Ausgeschieden
                                    </button>
                                </>
                            ) : null}
                            {stakeholder.status === 'TERMIN' ? (
                                <>
                                    <button className={`btn btn-sm btn-success btn-block mb-2`} onClick={(e) => ri_real_estate_update_stakeholder(stakeholder.id, 'AUSWAHL')}>
                                        Weiteres Interesse
                                    </button>
                                    <textarea className={`form-control control-border mb-2`} rows={3} placeholder="Absagegrund" id={`absage_${stakeholder.id}`} />
                                    <button
                                        className={`btn btn-sm btn-secondary btn-block`}
                                        onClick={(e) =>
                                            ri_real_estate_update_stakeholder(stakeholder.id, 'AUSGESCHIEDEN', {
                                                declineReason: (document.getElementById(`absage_${stakeholder.id}`) as HTMLInputElement).value,
                                            })
                                        }
                                    >
                                        Abgesagt / Ausgeschieden
                                    </button>
                                </>
                            ) : null}
                            {stakeholder.status === 'AUSWAHL' ? (
                                <>
                                    <textarea className={`form-control control-border mb-2`} rows={3} placeholder="Absagegrund" id={`absage_${stakeholder.id}`} />
                                    <button
                                        className={`btn btn-sm btn-secondary btn-block`}
                                        onClick={(e) =>
                                            ri_real_estate_update_stakeholder(stakeholder.id, 'AUSGESCHIEDEN', {
                                                declineReason: (document.getElementById(`absage_${stakeholder.id}`) as HTMLInputElement).value,
                                            })
                                        }
                                    >
                                        Abgesagt / Ausgeschieden
                                    </button>
                                </>
                            ) : null}
                            {stakeholder.status === 'AUSGESCHIEDEN' ? (
                                <>
                                    <button
                                        className={`btn btn-sm btn-secondary btn-block`}
                                        onClick={(e) =>
                                            ri_real_estate_update_stakeholder(stakeholder.id, 'EXPOSE', {
                                                appointment: null,
                                                declineReason: null,
                                            })
                                        }
                                    >
                                        zurück zu Exposé verschickt
                                    </button>
                                </>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        );
    };

    const toDate = (date: Date) => {
        return new Intl.DateTimeFormat('de-de', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(date).getTime());
    };

    return (
        <div>
            {dataTmp && objekt ? (
                <>
                    <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                    {/* <div className="row">
                        <div className="col-12 text-center">
                            <h4>
                                {' '}
                                <small>
                                    {dataTmp && dataTmp.title ? dataTmp.title : ''} ({objekt.objectId})
                                </small>
                            </h4>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                        {/* <li className="pt-2 px-3">
                                            <h3 className="card-title">{objekt.objectId}</h3>
                                        </li> */}
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="custom-tabs-two-data-tab"
                                                data-toggle="pill"
                                                href="#custom-tabs-two-data"
                                                role="tab"
                                                aria-controls="custom-tabs-two-data"
                                                aria-selected="true"
                                            >
                                                Daten
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="custom-tabs-two-texts-tab"
                                                data-toggle="pill"
                                                href="#custom-tabs-two-texts"
                                                role="tab"
                                                aria-controls="custom-tabs-two-texts"
                                                aria-selected="false"
                                            >
                                                Texte
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="custom-tabs-two-pictures-tab"
                                                data-toggle="pill"
                                                href="#custom-tabs-two-pictures"
                                                role="tab"
                                                aria-controls="custom-tabs-two-pictures"
                                                aria-selected="false"
                                            >
                                                Bilder
                                            </a>
                                        </li>
                                        {dataTmp && (dataTmp.realEstateType === 'realestates.houseBuy' || dataTmp.realEstateType === 'realestates.livingBuySite') ? (
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="custom-tabs-two-checklist-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-two-checklist"
                                                    role="tab"
                                                    aria-controls="custom-tabs-two-checklist"
                                                    aria-selected="false"
                                                >
                                                    <span className="d-none d-sm-block">Checkliste</span>
                                                    <span className="d-block d-sm-none">CL</span>
                                                </a>
                                            </li>
                                        ) : null}
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="custom-tabs-two-people-tab"
                                                data-toggle="pill"
                                                href="#custom-tabs-two-people"
                                                role="tab"
                                                aria-controls="custom-tabs-two-people"
                                                aria-selected="false"
                                            >
                                                <span className="d-none d-sm-block">Interessenten</span>
                                                <span className="d-block d-sm-none">Int.</span>
                                            </a>
                                        </li>
                                        {/* <li className="pt-2 px-3">
                                    <h3 className="card-title">CMD+S: Speichern, CMD+K: Kurzexpose, CMD+E: Expose, CMD+B: Checkliste</h3>
                                </li> */}
                                    </ul>

                                    {/* <h3 className="card-title align-middle" style={{ marginTop: '3px' }}>
                                {dataTmp.title} ({objekt.objectId}) - CMD+S: Speichern, CMD+K: Kurzexpose, CMD+E: Expose, CMD+B: Checkliste
                            </h3>  
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" onClick={(e) => history.push(`/ri/real-estate/objects/`)}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div> */}
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-two-tabContent">
                                        <div className="tab-pane fade active show" id="custom-tabs-two-data" role="tabpanel" aria-labelledby="custom-tabs-two-data-tab">
                                            <div className="row">
                                                <div className="col-lg-6 col-xl-6">{inputField('Titel (max. 100 Zeichen)*', 'title', '12', 'text', 'mb-4', '', '', 100, dataTmp.externalId)}</div>
                                                <div className="form-group col-lg-3 col-xl-3">
                                                    <label>Titel Kurz-Exposé (max. 50 Zeichen)</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <button
                                                                className="btn btn-outline-primary"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        shortTitle: dataTmp.title,
                                                                    });
                                                                }}
                                                            >
                                                                Titel übernehmen
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className={`form-control text-left`}
                                                            placeholder=""
                                                            value={dataTmp.shortTitle ? dataTmp.shortTitle : ''}
                                                            onChange={(e) =>
                                                                setDataTmp({
                                                                    ...dataTmp,
                                                                    shortTitle: e.target.value,
                                                                })
                                                            }
                                                            maxLength={50}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-3 col-xl-3">
                                                    <label>Passcode</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={`form-control text-right`}
                                                            placeholder=""
                                                            value={dataTmp.passcode ? dataTmp.passcode : ''}
                                                            onChange={(e) =>
                                                                setDataTmp({
                                                                    ...dataTmp,
                                                                    passcode: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                className="btn btn-outline-primary"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    copyToClipboard(
                                                                        dataTmp.passcode
                                                                            ? `https://rauprecht-immobilien.de/angebote/${dataTmp.externalId}?passcode=${dataTmp.passcode}`
                                                                            : `https://rauprecht-immobilien.de/angebote/${dataTmp.externalId}`
                                                                    );
                                                                    fireToast('info', `URL im Zwischenspeicher`);
                                                                }}
                                                            >
                                                                URL kopieren
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">Homepage veröffentlichen</h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch onChange={(e) => setPublishedHomepage(e)} checked={publishedHomepage ? publishedHomepage : false} uncheckedIcon={false} />
                                                        </h3>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">Kleinanzeigen Veröffentlichen</h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        publishedEbay: e,
                                                                    })
                                                                }
                                                                checked={dataTmp.publishedEbay ? dataTmp.publishedEbay : false}
                                                                uncheckedIcon={false}
                                                                disabled={false}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">
                                                            Scout Speichern{' '}
                                                            {dataTmp.immoscoutObjectId ? (
                                                                <a href={`https://www.immobilienscout24.de/scoutmanager/exposepreview/${dataTmp.immoscoutObjectId}/browser.html`} target="_blank">
                                                                    ({dataTmp.immoscoutObjectId})
                                                                </a>
                                                            ) : null}
                                                        </h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch
                                                                onChange={(e) => {
                                                                    let synchEstateTmp = dataTmp.synchEstate ? { ...dataTmp.synchEstate } : {};
                                                                    synchEstateTmp.publishedScout = false;
                                                                    synchEstateTmp.publishedOldHomepage = false;
                                                                    synchEstateTmp.saveScout = false;
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        saveScout: e,
                                                                        publishedScout: false,
                                                                        publishedOldHomepage: false,
                                                                        synchEstate: synchEstateTmp,
                                                                    });
                                                                }}
                                                                checked={dataTmp.saveScout ? dataTmp.saveScout : false}
                                                                uncheckedIcon={false}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">Scout Veröffentlichen</h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        publishedScout: e,
                                                                    })
                                                                }
                                                                checked={dataTmp.publishedScout ? dataTmp.publishedScout : false}
                                                                disabled={dataTmp.saveScout ? false : true}
                                                                uncheckedIcon={false}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">Alte Homepage veröffentlichen</h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        publishedOldHomepage: e,
                                                                    })
                                                                }
                                                                checked={dataTmp.publishedOldHomepage ? dataTmp.publishedOldHomepage : false}
                                                                disabled={dataTmp.saveScout ? false : true}
                                                                uncheckedIcon={false}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">IVD Veröffentlichen</h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        publishedIVD: e,
                                                                    })
                                                                }
                                                                checked={dataTmp.publishedIVD ? dataTmp.publishedIVD : false}
                                                                uncheckedIcon={false}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className="callout callout-info" style={{ height: '85px' }}>
                                                        <h5 className="text-center mt-2">immowelt Veröffentlichen</h5>
                                                        <h3 className="text-center font-weight-bold">
                                                            <Switch
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        publishedImmowelt: e,
                                                                    })
                                                                }
                                                                checked={dataTmp.publishedImmowelt ? dataTmp.publishedImmowelt : false}
                                                                uncheckedIcon={false}
                                                                disabled={false}
                                                            />
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* houseBuy specific values START */}
                                            {dataTmp.realEstateType === 'realestates.houseBuy' || dataTmp.realEstateType === 'realestates.apartmentBuy' ? (
                                                <div className="row">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div
                                                            className={`card card-success mb-1 ${
                                                                !dataTmp.synchEstate || (dataTmp.synchEstate && !dataTmp.synchEstate.saveScout) ? 'collapsed-card' : null
                                                            }`}
                                                        >
                                                            <div className="btn btn-sm btn-outline-primary btn-block align-middle" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                                                Synchrone Anlageimmobilie
                                                            </div>
                                                            <div className="card-body box-profile">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        <div className="callout callout-info mr-3" style={{ height: '85px' }}>
                                                                            <h5 className="text-center mt-2">Anlageimmobilien-ID</h5>
                                                                            <h3 className="text-center font-weight-bold">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control text-center"
                                                                                    placeholder=""
                                                                                    value={dataTmp.synchEstate && dataTmp.synchEstate.externalId ? dataTmp.synchEstate.externalId : ''}
                                                                                    disabled
                                                                                />
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        <div className="callout callout-info mr-3" style={{ height: '85px' }}>
                                                                            <h5 className="text-center mt-2">
                                                                                Scout Speichern{' '}
                                                                                {dataTmp.synchEstate && dataTmp.synchEstate.immoscoutObjectId ? (
                                                                                    <a
                                                                                        href={`https://www.immobilienscout24.de/scoutmanager/exposepreview/${dataTmp.synchEstate.immoscoutObjectId}/browser.html`}
                                                                                        target="_blank"
                                                                                    >
                                                                                        ({dataTmp.synchEstate.immoscoutObjectId})
                                                                                    </a>
                                                                                ) : null}
                                                                            </h5>
                                                                            <h3 className="text-center font-weight-bold">
                                                                                <Switch
                                                                                    onChange={(e) => {
                                                                                        let synchEstateTmp = dataTmp.synchEstate ? { ...dataTmp.synchEstate } : {};
                                                                                        synchEstateTmp.saveScout = e;
                                                                                        if (synchEstateTmp.saveScout) {
                                                                                            synchEstateTmp.externalId = `${objekt.objectId}-A`;
                                                                                            synchEstateTmp.realEstateType = 'realestates.investment';
                                                                                        }
                                                                                        if (!synchEstateTmp.saveScout) {
                                                                                            synchEstateTmp.publishedScout = false;
                                                                                            synchEstateTmp.publishedOldHomepage = false;
                                                                                            synchEstateTmp.externalId = ``;
                                                                                            synchEstateTmp.realEstateType = ``;
                                                                                        }
                                                                                        setDataTmp({
                                                                                            ...dataTmp,
                                                                                            synchEstate: synchEstateTmp,
                                                                                        });
                                                                                    }}
                                                                                    checked={dataTmp.synchEstate && dataTmp.synchEstate.saveScout ? dataTmp.synchEstate.saveScout : false}
                                                                                    disabled={dataTmp.saveScout ? false : true}
                                                                                    uncheckedIcon={false}
                                                                                />
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        <div className="callout callout-info ml-3" style={{ height: '85px' }}>
                                                                            <h5 className="text-center mt-2">Scout Veröffentlichen</h5>
                                                                            <h3 className="text-center font-weight-bold">
                                                                                <Switch
                                                                                    onChange={(e) => {
                                                                                        let synchEstateTmp = dataTmp.synchEstate ? { ...dataTmp.synchEstate } : {};
                                                                                        synchEstateTmp.publishedScout = e;

                                                                                        setDataTmp({
                                                                                            ...dataTmp,
                                                                                            synchEstate: synchEstateTmp,
                                                                                        });
                                                                                    }}
                                                                                    checked={dataTmp.synchEstate && dataTmp.synchEstate.publishedScout ? dataTmp.synchEstate.publishedScout : false}
                                                                                    disabled={dataTmp.saveScout && dataTmp.synchEstate && dataTmp.synchEstate.saveScout ? false : true}
                                                                                    uncheckedIcon={false}
                                                                                />
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-lg-3 col-xl-3">
                                                                        <div className="callout callout-info ml-3" style={{ height: '85px' }}>
                                                                            <h5 className="text-center mt-2">Alte Homepage veröffentlichen</h5>
                                                                            <h3 className="text-center font-weight-bold">
                                                                                <Switch
                                                                                    onChange={(e) => {
                                                                                        let synchEstateTmp = dataTmp.synchEstate ? { ...dataTmp.synchEstate } : {};
                                                                                        synchEstateTmp.publishedOldHomepage = e;

                                                                                        setDataTmp({
                                                                                            ...dataTmp,
                                                                                            synchEstate: synchEstateTmp,
                                                                                        });
                                                                                    }}
                                                                                    checked={
                                                                                        dataTmp.synchEstate && dataTmp.synchEstate.publishedOldHomepage
                                                                                            ? dataTmp.synchEstate.publishedOldHomepage
                                                                                            : false
                                                                                    }
                                                                                    disabled={dataTmp.saveScout && dataTmp.synchEstate && dataTmp.synchEstate.saveScout ? false : true}
                                                                                    uncheckedIcon={false}
                                                                                />
                                                                            </h3>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        {anlagetyp()}
                                                                        {inputField('Preis pro m²', 'pricePerSQM', '12', 'number', '', 'text-right', '€')}
                                                                        {switchSelect('Aufzug', 'lift', '12', '', true, false)}
                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        {inputField('Vermietbare Fläche*', 'netFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                                        {inputField('Gesamtfläche*', 'totalFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                                        {inputField('Gewerbefläche*', 'industrialArea', '12', 'number', '', 'text-right', 'm²')}
                                                                        {inputField('Sonstige Fläche', 'additionalArea', '12', 'number', '', 'text-right', 'm²')}
                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        {inputField('x-fache Mieteinnahmen', 'priceMultiplier', '12', 'number')}
                                                                        {inputField('Mieteinnahmen Ist', 'rentalIncomeActual', '12', 'number', '', 'text-right', '€')}
                                                                        {inputField('Mieteinnahmen Soll', 'rentalIncomeTarget', '12', 'number', '', 'text-right', '€')}
                                                                        {inputField('nicht umlegbare Kosten', 'otherCosts', '12', 'number', '', 'text-right', '€')}
                                                                    </div>
                                                                    <div className="col-lg-3 col-xl-3">
                                                                        {inputField('Fahrzeit zum nächsten Bahnhof', 'distanceToMRS', '12', 'number', '', 'text-right', 'Minuten')}
                                                                        {inputField('Fahrzeit zur nächsten Autobahn', 'distanceToFM', '12', 'number', '', 'text-right', 'Minuten')}
                                                                        {inputField('Laufzeit zum Öffentl. Personennahverkehr', 'distanceToPT', '12', 'number', '', 'text-right', 'Minuten')}
                                                                        {inputField('Fahrzeit zum nächsten Flughafen', 'distanceToAirport', '12', 'number', '', 'text-right', 'Minuten')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/* houseBuy specific values END */}
                                            <div className="row mt-2">
                                                <div className="col-lg-3 col-xl-3">
                                                    {/* houseBuy specific values START */}
                                                    {dataTmp.realEstateType === 'realestates.houseBuy' ? inputField('Kaufpreis*', 'value', '12', 'number', '', 'text-right', '€') : null}
                                                    {/* houseBuy specific values END */}

                                                    {/* apartmentBuy specific values START */}
                                                    {dataTmp.realEstateType === 'realestates.apartmentBuy' ? inputField('Kaufpreis*', 'value', '12', 'number', '', 'text-right', '€') : null}
                                                    {/* apartmentBuy specific values END */}

                                                    {/* livingBuySite specific values START */}
                                                    {dataTmp.realEstateType === 'realestates.livingBuySite' ? inputField('Kaufpreis*', 'value', '12', 'number', '', 'text-right', '€') : null}
                                                    {/* livingBuySite specific values END */}

                                                    {/* investment specific values START */}
                                                    {dataTmp.realEstateType === 'realestates.investment' ? inputField('Kaufpreis*', 'value', '12', 'number', '', 'text-right', '€') : null}
                                                    {/* investment specific values END */}

                                                    {/* garageBuy specific values START */}
                                                    {dataTmp.realEstateType === 'realestates.garageBuy' ? inputField('Kaufpreis*', 'value', '12', 'number', '', 'text-right', '€') : null}
                                                    {/* garageBuy specific values END */}

                                                    {/* tradeSite specific values START */}
                                                    {dataTmp.realEstateType === 'realestates.tradeSite' ? inputField('Kaufpreis*', 'value', '12', 'number', '', 'text-right', '€') : null}
                                                    {/* tradeSite specific values END */}

                                                    <div className="row">
                                                        <div className="form-group col-lg-9">
                                                            <label>Straße</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={dataTmp.realStreet ? dataTmp.realStreet : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            realStreet: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-lg-3">
                                                            <label>Nr.</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={dataTmp.realHouseNumber ? dataTmp.realHouseNumber : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            realHouseNumber: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-lg-9">
                                                            <label className="text-secondary">IS24-Straße*</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${dataTmp && (!dataTmp.street || dataTmp.street.length === 0) ? 'border-danger' : null}`}
                                                                    placeholder=""
                                                                    value={dataTmp.street ? dataTmp.street : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            street: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-lg-3">
                                                            <label className="text-secondary">IS24-Hausnr.*</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${dataTmp && (!dataTmp.houseNumber || dataTmp.houseNumber.length === 0) ? 'border-danger' : null}`}
                                                                    placeholder=""
                                                                    value={dataTmp.houseNumber ? dataTmp.houseNumber : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            houseNumber: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="form-group col-lg-3">
                                                            <label>PLZ*</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${dataTmp && (!dataTmp.postcode || dataTmp.postcode.length === 0) ? 'border-danger' : null}`}
                                                                    placeholder=""
                                                                    value={dataTmp.postcode ? dataTmp.postcode : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            postcode: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group col-lg-9">
                                                            <label>Ort*</label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${dataTmp && (!dataTmp.city || dataTmp.city.length === 0) ? 'border-danger' : null}`}
                                                                    placeholder=""
                                                                    value={dataTmp.city ? dataTmp.city : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            city: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-lg-12">
                                                            <div className="input-group">
                                                                <Switch
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            showAddress: e,
                                                                        })
                                                                    }
                                                                    className="align-middle"
                                                                    checked={dataTmp.showAddress ? dataTmp.showAddress : false}
                                                                    uncheckedIcon={false}
                                                                />
                                                                <label className="align-middle mt-1 ml-2">Adresse in den Portalen anzeigen*</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {inputField('Provision*', 'courtage', '12', 'text')}
                                                    <div className="row">
                                                        <div className="form-group col-lg-12">
                                                            <label>Provisionshinweis</label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={5}
                                                                    value={dataTmp.courtageNote ? dataTmp.courtageNote : ''}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            courtageNote: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="form-group col-lg-12">{immobilienStatus()}</div>
                                                    </div>
                                                </div>
                                                {/* houseBuy specific values START */}
                                                {dataTmp.realEstateType === 'realestates.houseBuy' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {hausTyp()}
                                                            {objektZustand()}
                                                            {bauphase()}
                                                            {inputField('Baujahr*', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {inputField('Wohngeld', 'serviceCharge', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Mieteinnahmen pro Monat', 'rentalIncome', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Stellplatz Kaufpreis', 'parkingSpacePrice', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('Nutzfläche', 'usableFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Wohnfläche*', 'livingSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Grundstücksfläche*', 'plotArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {inputField('Zimmerzahl*', 'numberOfRooms', '12', 'number', '', 'text-right', 'Zimmer')}
                                                            {inputField('Anzahl Schlafzimmer', 'numberOfBedRooms', '12', 'number', '', 'text-right', 'Schlafzimmer')}
                                                            {inputField('Anzahl Badezimmer', 'numberOfBathRooms', '12', 'number', '', 'text-right', 'Badezimmer')}
                                                            {parkplatz()}
                                                            {inputField('Anzahl Stellplätze', 'numberOfParkingSpaces', '12', 'number', '', 'text-right', 'Stellplätze')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}
                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* houseBuy specific values END */}

                                                {/* houseRent specific values START */}
                                                {dataTmp.realEstateType === 'realestates.houseRent' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {hausTyp()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {haustiereErlaubt()}
                                                            {inputField('Kaltmiete*', 'baseRent', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Warmmiete', 'totalRent', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('Wohnfläche*', 'livingSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Grundstücksfläche*', 'plotArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {inputField('Zimmerzahl*', 'numberOfRooms', '12', 'number', '', 'text-right', 'Zimmer')}
                                                            {inputField('Anzahl Schlafzimmer', 'numberOfBedRooms', '12', 'number', '', 'text-right', 'Schlafzimmer')}
                                                            {inputField('Anzahl Badezimmer', 'numberOfBathRooms', '12', 'number', '', 'text-right', 'Badezimmer')}
                                                            {inputField('Nebenkosten', 'serviceCharge', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Kaution', 'deposit', '12', 'text')}
                                                            {inputField('Heizkosten', 'heatingCosts', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {parkplatz()}
                                                            {inputField('Anzahl Stellplätze', 'numberOfParkingSpaces', '12', 'number', '', 'text-right', 'Stellplätze')}
                                                            {inputField('Stellplatz-Miete', 'parkingSpacePrice', '12', 'number', '', 'text-right', '€')}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}
                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* houseRent specific values END */}

                                                {/* apartmentBuy specific values START */}
                                                {dataTmp.realEstateType === 'realestates.apartmentBuy' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {wohnungskategorie()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {inputField('Wohngeld', 'serviceCharge', '12', 'number', '', 'text-right', '€')}
                                                            {parkplatz()}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Etage', 'floor', '12', 'number', '', 'text-right', '. Etage')}
                                                            {inputField('Wohnfläche*', 'livingSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Zimmerzahl*', 'numberOfRooms', '12', 'number', '', 'text-right', 'Zimmer')}
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {inputField('Nutzfläche', 'usableFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Anzahl Schlafzimmer', 'numberOfBedRooms', '12', 'number', '', 'text-right', 'Schlafzimmer')}
                                                            {inputField('Anzahl Badezimmer', 'numberOfBathRooms', '12', 'number', '', 'text-right', 'Badezimmer')}
                                                            {inputField('Mieteinnahmen pro Monat', 'rentalIncome', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Anzahl Stellplätze', 'numberOfParkingSpaces', '12', 'number', '', 'text-right', 'Stellplätze')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}
                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                            {inputField('Stellplatz Kaufpreis', 'parkingSpacePrice', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* apartmentBuy specific values END */}

                                                {/* apartmentRent specific values START */}
                                                {dataTmp.realEstateType === 'realestates.apartmentRent' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {wohnungskategorie()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {inputField('Kaltmiete*', 'baseRent', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Warmmiete', 'totalRent', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Nebenkosten', 'serviceCharge', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Kaution', 'deposit', '12', 'text')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Etage', 'floor', '12', 'number', '', 'text-right', '. Etage')}
                                                            {inputField('Wohnfläche*', 'livingSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Zimmerzahl*', 'numberOfRooms', '12', 'number', '', 'text-right', 'Zimmer')}
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {inputField('Nutzfläche', 'usableFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Anzahl Schlafzimmer', 'numberOfBedRooms', '12', 'number', '', 'text-right', 'Schlafzimmer')}
                                                            {inputField('Anzahl Badezimmer', 'numberOfBathRooms', '12', 'number', '', 'text-right', 'Badezimmer')}
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {parkplatz()}
                                                            {inputField('Anzahl Stellplätze', 'numberOfParkingSpaces', '12', 'number', '', 'text-right', 'Stellplätze')}
                                                            {inputField('Stellplatz-Miete', 'parkingSpacePrice', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {haustiereErlaubt()}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}
                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                            {inputField('Heizkosten', 'heatingCosts', '12', 'number', '', 'text-right', '€')}
                                                            {switchSelect('Heizkosten in Nebenkosten', 'heatingCostsInServiceCharge')}
                                                            {switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* apartmentRent specific values END */}

                                                {/* livingBuySite specific values START */}
                                                {dataTmp.realEstateType === 'realestates.livingBuySite' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {empfohleneNutzungsarten()}
                                                            {inputField('Grundstücksfläche*', 'plotArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Teilbar ab', 'minDivisible', '12', 'number')}
                                                            {switchSelect('Kurzfristig bebaubar', 'shortTermConstructible', '12', '', true, false)}
                                                            {switchSelect('Baugenehmigung vorhanden', 'buildingPermission', '12', '', true, false)}
                                                            {switchSelect('Abriss erforderlich', 'demolition', '12', '', true, false)}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {erschliessungszustand()}
                                                            {bebaubarNach()}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('GRZ', 'grz', '12', 'number')}
                                                            {inputField('GFZ', 'gfz', '12', 'number')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* livingBuySite specific values END */}

                                                {/* office specific values START */}
                                                {dataTmp.realEstateType === 'realestates.office' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {buerotyp()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {datenverkabelung()}
                                                            {klimaanlage()}
                                                            {bodenbelag()}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Laufzeit zum Öffentl. Personennahverkehr', 'distanceToPT', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Fahrzeit zum nächsten Flughafen', 'distanceToAirport', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Büro-/Praxisfläche*', 'netFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Gesamtfläche*', 'totalFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Teilbar ab', 'minDivisible', '12', 'number')}
                                                            {preisFlaecheOderZeitraum()}
                                                            {inputField('Miete*', 'value', '12', 'number', '', 'text-right', '€')}
                                                            {switchSelect('Preis inkl. Zusatzservices', 'additionalServicesIncluded')}
                                                            {inputField('Nebenkosten', 'additionalCosts', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Kaution', 'deposit', '12', 'text')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {inputField('Fahrzeit zum nächsten Bahnhof', 'distanceToMRS', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Fahrzeit zur nächsten Autobahn', 'distanceToFM', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('Anzahl Stellplätze', 'numberOfParkingSpaces', '12', 'number', '', 'text-right', 'Stellplätze')}
                                                            {inputField('Stellplatz-Miete', 'parkingSpacePrice', '12', 'number', '', 'text-right', '€')}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}
                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* office specific values END */}

                                                {/* investment specific values START */}
                                                {dataTmp.realEstateType === 'realestates.investment' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {anlagetyp()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr*', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {switchSelect('Aufzug', 'lift', '12', '', true, false)}
                                                            {switchSelect('Denkmalschutzobjekt', 'listed')}
                                                            {switchSelect('Kamin', 'fireplace', '12', '', true, false)}
                                                            {switchSelect('Keller', 'cellar')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('Fahrzeit zum nächsten Bahnhof', 'distanceToMRS', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Fahrzeit zur nächsten Autobahn', 'distanceToFM', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Laufzeit zum Öffentl. Personennahverkehr', 'distanceToPT', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Fahrzeit zum nächsten Flughafen', 'distanceToAirport', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Betriebskosten', 'additionalCosts', '12', 'number', '', 'text-right', '€')}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}
                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                            {switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}
                                                            {switchSelect('Energieausweis', 'energyPerformanceCertificate', '12', '', true, false)}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Gewerbefläche*', 'industrialArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Grundstücksfläche*', 'plotArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Vermietbare Fläche*', 'netFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Gesamtfläche', 'totalFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Wohnfläche', 'livingSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Zimmerzahl', 'numberOfRooms', '12', 'number', '', 'text-right', 'Zimmer')}
                                                            {inputField('Sonstige Fläche', 'additionalArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Preis pro m²', 'pricePerSQM', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('x-fache Mieteinnahmen', 'priceMultiplier', '12', 'number')}
                                                            {inputField('Mieteinnahmen Ist', 'rentalIncomeActual', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Mieteinnahmen Soll', 'rentalIncomeTarget', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('nicht umlegbare Kosten', 'otherCosts', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* investment specific values END */}

                                                {/* garageBuy specific values START */}
                                                {dataTmp.realEstateType === 'realestates.garageBuy' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {garagentyp()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr', 'constructionYear', '12', 'number')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Länge', 'lengthGarage', '12', 'number', '', 'text-right', 'm')}
                                                            {inputField('Breite', 'widthGarage', '12', 'number', '', 'text-right', 'm')}
                                                            {inputField('Höhe', 'heightGarage', '12', 'number', '', 'text-right', 'm')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {inputField('Nutzfläche', 'usableFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* garageBuy specific values END */}

                                                {/* garageRent specific values START */}
                                                {dataTmp.realEstateType === 'realestates.garageRent' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {garagentyp()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr', 'constructionYear', '12', 'number')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Länge', 'lengthGarage', '12', 'number', '', 'text-right', 'm')}
                                                            {inputField('Breite', 'widthGarage', '12', 'number', '', 'text-right', 'm')}
                                                            {inputField('Höhe', 'heightGarage', '12', 'number', '', 'text-right', 'm')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('Frei bis', 'freeUntil', '12', 'text')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {inputField('Nutzfläche', 'usableFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Miete*', 'value', '12', 'number', '', 'text-right', '€')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* garageRent specific values END */}

                                                {/* store specific values START */}
                                                {dataTmp.realEstateType === 'realestates.store' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {ladentyp()}
                                                            {objektZustand()}
                                                            {inputField('Baujahr*', 'constructionYear', '12', 'number')}
                                                            {inputField('letzte Modernisierung', 'lastRefurbishment', '12', 'number')}
                                                            {qualitaetDerAusstattung()}
                                                            {heizungsartIS()}
                                                            {heizungsartOI()}
                                                            {befeuerungsarten()}
                                                            {befeuerungsartenNeu()}
                                                            {lageart()}
                                                            {zulieferungsart()}
                                                            {bodenbelag()}
                                                            {inputField('Laufzeit zum Öffentl. Personennahverkehr', 'distanceToPT', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Fahrzeit zum nächsten Flughafen', 'distanceToAirport', '12', 'number', '', 'text-right', 'Minuten')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                            {inputField('Etagenanzahl', 'numberOfFloors', '12', 'number', '', 'text-right', 'Etage(n)')}
                                                            {inputField('Lastenaufzug Tragkraft', 'goodsLiftLoad', '12', 'number', '', 'text-right', 't')}
                                                            {inputField('Deckenlast', 'floorLoad', '12', 'number', '', 'text-right', 'kg/m²')}
                                                            {preisFlaecheOderZeitraum()}
                                                            {inputField('Miete*', 'value', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Kaution', 'deposit', '12', 'text')}
                                                            {inputField('Nebenkosten', 'additionalCosts', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Anzahl Stellplätze', 'numberOfParkingSpaces', '12', 'number', '', 'text-right', 'Stellplätze')}
                                                            {inputField('Stellplatz Kaufpreis', 'parkingSpacePrice', '12', 'number', '', 'text-right', '€')}
                                                            {inputField('Fahrzeit zum nächsten Bahnhof', 'distanceToMRS', '12', 'number', '', 'text-right', 'Minuten')}
                                                            {inputField('Fahrzeit zur nächsten Autobahn', 'distanceToFM', '12', 'number', '', 'text-right', 'Minuten')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Gesamtfläche*', 'totalFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Verkaufsfläche*', 'netFloorSpace', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Schaufensterfront', 'shopWindowLength', '12', 'number', '', 'text-right', 'm')}
                                                            {inputField('Nebenfläche', 'additionalArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Teilbar ab', 'minDivisible', '12', 'number')}
                                                            {energieausweisVerfuegbarkeit()}
                                                            {energieausweisDatum()}
                                                            {energieausweistyp()}
                                                            {inputField('Energieverbrauchskennwert', 'thermalCharacteristic', '12', 'number', '', 'text-right', 'kWh/(m²*a)')}

                                                            {inputField('Energieeffizienzklasse', 'energyEfficiencyClass', '12', 'text')}
                                                            {inputField('Energieausweis ausgestellt am', 'energyCertificateIssuedAt', '12', 'text')}
                                                            {inputField('Energieausweis gültig bis', 'energyCertificateValidTill', '12', 'text')}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* store specific values END */}

                                                {/* tradeSite specific values START */}
                                                {dataTmp.realEstateType === 'realestates.tradeSite' ? (
                                                    <>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {empfohleneNutzungsartenGewerbegrundstueck()}
                                                            {grundstueckskategorie()}
                                                            {erschliessungszustand()}
                                                            {inputField('Frei ab', 'freeFrom', '12', 'text')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {inputField('Grundstücksfläche*', 'plotArea', '12', 'number', '', 'text-right', 'm²')}
                                                            {inputField('Teilbar ab', 'minDivisible', '12', 'number')}
                                                            {inputField('GRZ', 'grz', '12', 'number')}
                                                            {inputField('GFZ', 'gfz', '12', 'number')}
                                                        </div>
                                                        <div className="col-lg-3 col-xl-3">
                                                            {bebaubarNach()}
                                                            {switchSelect('Kurzfristig bebaubar', 'shortTermConstructible', '12', '', true, false)}
                                                            {switchSelect('Baugenehmigung vorhanden', 'buildingPermission', '12', '', true, false)}
                                                            {switchSelect('Abriss erforderlich', 'demolition', '12', '', true, false)}
                                                        </div>
                                                    </>
                                                ) : null}
                                                {/* tradeSite specific values END */}
                                            </div>

                                            {/* houseBuy specific values START */}
                                            {dataTmp.realEstateType === 'realestates.houseBuy' ? (
                                                <div className="row mb-4">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('mit Einliegerewohnung', 'lodgerFlat')}
                                                        {switchSelect('barrierefrei', 'handicappedAccessible')}
                                                        {switchSelect('Denkmalschutzobjekt', 'listed')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Keller', 'cellar')}
                                                        {switchSelect('Gäste-WC', 'guestToilet')}
                                                        {switchSelect('Kamin', 'fireplace', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('vermietet', 'rented')}
                                                        {switchSelect('Energieausweis', 'energyPerformanceCertificate', '12', '', true, false)}
                                                        {switchSelect('klimatisiert', 'airConditioned', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}
                                                        {switchSelect('Als Ferienwohnung geeignet', 'summerResidencePractical')}
                                                        {switchSelect('Nicht-Wohngebäude', 'nonLivingEstate', '12', '', true, false)}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/* houseBuy specific values END */}

                                            {/* houseRent specific values START */}
                                            {dataTmp.realEstateType === 'realestates.houseRent' ? (
                                                <div className="row mb-4">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Gäste-WC', 'guestToilet')}
                                                        {switchSelect('WG-geeignet', 'useAsFlatshareRoom')}
                                                        {switchSelect('Einbauküche', 'builtInKitchen')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Heizkosten in Nebenkosten', 'heatingCostsInServiceCharge')}
                                                        {switchSelect('Kamin', 'fireplace', '12', '', true, false)}
                                                        {switchSelect('barrierefrei', 'handicappedAccessible')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Energieausweis', 'energyPerformanceCertificate', '12', '', true, false)}
                                                        {switchSelect('Keller', 'cellar')}
                                                        {switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('klimatisiert', 'airConditioned', '12', '', true, false)}
                                                        {switchSelect('Nicht-Wohngebäude', 'nonLivingEstate', '12', '', true, false)}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/* houseBuy specific values END */}

                                            {/* apartmentBuy specific values START */}
                                            {dataTmp.realEstateType === 'realestates.apartmentBuy' ? (
                                                <div className="row mb-4">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Aufzug', 'lift', '12', '', true, false)}
                                                        {switchSelect('Keller', 'cellar')}
                                                        {switchSelect('Einbauküche', 'builtInKitchen', '12', '', true, false)}
                                                        {switchSelect('klimatisiert', 'airConditioned', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('barrierefrei', 'handicappedAccessible')}
                                                        {switchSelect('Gäste-WC', 'guestToilet')}
                                                        {switchSelect('Balkon', 'balcony', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Denkmalschutzobjekt', 'listed')}
                                                        {switchSelect('Als Ferienwohnung geeignet', 'summerResidencePractical')}
                                                        {switchSelect('vermietet', 'rented')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}
                                                        {switchSelect('Energieausweis', 'energyPerformanceCertificate', '12', '', true, false)}
                                                        {switchSelect('Garten', 'garden', '12', '', true, false)}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/* apartmentBuy specific values END */}

                                            {/* apartmentRent specific values START */}
                                            {dataTmp.realEstateType === 'realestates.apartmentRent' ? (
                                                <div className="row mb-4">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Gäste-WC', 'guestToilet')}
                                                        {switchSelect('WG-geeignet', 'useAsFlatshareRoom')}
                                                        {switchSelect('Einbauküche', 'builtInKitchen', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Balkon', 'balcony', '12', '', true, false)}
                                                        {switchSelect('Garten', 'garden', '12', '', true, false)}
                                                        {switchSelect('barrierefrei', 'handicappedAccessible')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Energieausweis', 'energyPerformanceCertificate', '12', '', true, false)}
                                                        {switchSelect('Keller', 'cellar')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Aufzug', 'lift', '12', '', true, false)}
                                                        {switchSelect('WBS-Schein erforderlich', 'certificateOfEligibilityNeeded', '12', '', true, false)}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/* apartmentBuy specific values END */}

                                            {/* office specific values START */}
                                            {dataTmp.realEstateType === 'realestates.office' ? (
                                                <div className="row mb-4">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Aufzug', 'lift', '12', '', true, false)}
                                                        {switchSelect('Keller', 'cellar')}
                                                        {switchSelect('klimatisiert', 'airConditioned', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('barrierefrei', 'handicappedAccessible')}
                                                        {switchSelect('Kantine', 'hasCanteen')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Starkstrom', 'highVoltage')}
                                                        {switchSelect('Küche', 'kitchenComplete')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Denkmalschutzobjekt', 'listed')}
                                                        {switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/* office specific values END */}

                                            {/* store specific values START */}
                                            {dataTmp.realEstateType === 'realestates.store' ? (
                                                <div className="row mb-4">
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Aufzug', 'lift', '12', '')}
                                                        {switchSelect('klimatisiert', 'airConditioned', '12', '', true, false)}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Rampe', 'ramp', '12', '')}
                                                        {switchSelect('Denkmalschutzobjekt', 'listed')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">
                                                        {switchSelect('Lastenaufzug', 'goodsLift', '12', '')}
                                                        {switchSelect('Keller', 'cellar')}
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3">{switchSelect('Endenergiebedarf enthält Warmwasser', 'energyConsumptionContainsWarmWater')}</div>
                                                </div>
                                            ) : null}
                                            {/* store specific values END */}

                                            <div className="row pt-4 border-top mb-3">
                                                <div className="col-lg-3 col-xl-3">{switchSelect('dem Exposé eine leere Seite hinzufügen', 'addEmptyPageToExpose', '12', '', true, false)}</div>
                                                <div className="col-lg-3 col-xl-3">
                                                    <div className={`form-group`}>
                                                        <label>Abstand Objektüberblick Überschrift / Bild zu Tabelle</label>
                                                        <div className="input-group">
                                                            <input
                                                                type="range"
                                                                className="custom-range"
                                                                onChange={(e) => {
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        exposeObjectOverviewDistance: parseInt(e.target.value),
                                                                    });
                                                                }}
                                                                value={dataTmp.exposeObjectOverviewDistance ? dataTmp.exposeObjectOverviewDistance : 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="form-group col-lg-12">
                                                        <label>zusätzliche Merkmale für Objektübersicht im Exposé (nicht öffentlich)</label>
                                                        <div className="input-group">
                                                            <textarea
                                                                className="form-control"
                                                                rows={10}
                                                                placeholder="Merkmal: Wert"
                                                                value={dataTmp.additionalAttributesExpose}
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        additionalAttributesExpose: e.target.value,
                                                                    })
                                                                }
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-4 border-top">
                                                <div className="col-lg-3 col-xl-3">{inputField('Immoviewer ID', 'immoviewerId', '12', 'text')}</div>
                                                <div className="col-lg-3 col-xl-3">{inputField('Eigentümer', 'owner', '12', 'text')}</div>
                                                <div className="col-lg-3 col-xl-3">{switchSelect('Eigentümer Mehrzahl', 'ownerIsPlural', '12', '', true, false)}</div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-two-texts" role="tabpanel" aria-labelledby="custom-tabs-two-texts-tab">
                                            <div className="row mt-4">
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="row">
                                                        <div className="form-group col-lg-12">
                                                            <label>
                                                                Beschreibung (öffentlich){' '}
                                                                {/* <button onClick={(e) => ri_real_estate_get_texts(dataTmp)} className="ml-2 btn btn-sm btn-primary">
                                                    Textvorschlag
                                                </button> */}
                                                            </label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={10}
                                                                    value={dataTmp.descriptionNote}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            descriptionNote: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-lg-12">
                                                            <label>Ausstattung (öffentlich)</label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={10}
                                                                    value={dataTmp.furnishingNote}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            furnishingNote: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-xl-6">
                                                    <div className="row">
                                                        <div className="form-group col-lg-12">
                                                            <label>Lage (öffentlich)</label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={10}
                                                                    value={dataTmp.locationNote}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            locationNote: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-lg-12">
                                                            <label>Sonstige Angaben (öffentlich)</label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={10}
                                                                    value={dataTmp.otherNote}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            otherNote: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="form-group col-lg-12">
                                                        <label>Objektbeschreibung und Ausstattung (nicht öffentlich)</label>
                                                        <div className="row mt-4">
                                                            <div className="col-lg-6 col-xl-6">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={20}
                                                                    value={dataTmp.descriptionNoteLongKIHelp}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            descriptionNoteLongKIHelp: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                                <button
                                                                    onClick={(e) => {
                                                                        ri_real_estate_get_text_support(dataTmp, 'descriptionNoteLong');
                                                                    }}
                                                                    className={`btn btn-block btn-info mt-1`}
                                                                >
                                                                    Generieren
                                                                </button>
                                                            </div>
                                                            <div className="col-lg-6 col-xl-6">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={20}
                                                                    value={dataTmp.descriptionNoteLongKIText}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            descriptionNoteLongKIText: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                                <button
                                                                    onClick={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            descriptionNoteLong: dataTmp.descriptionNoteLongKIText ?? '',
                                                                        })
                                                                    }
                                                                    className={`btn btn-block btn-info mt-1`}
                                                                >
                                                                    Übernehmen
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-lg-12 col-xl-12">
                                                                <div className="input-group">
                                                                    <textarea
                                                                        className="form-control"
                                                                        rows={30}
                                                                        value={dataTmp.descriptionNoteLong}
                                                                        onChange={(e) =>
                                                                            setDataTmp({
                                                                                ...dataTmp,
                                                                                descriptionNoteLong: e.target.value,
                                                                            })
                                                                        }
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row mt-4">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="form-group col-lg-12">
                                        <label>Ausstattung (nicht öffentlich)</label>
                                        <div className="input-group">
                                            <textarea
                                                className="form-control"
                                                rows={30}
                                                value={dataTmp.furnishingNoteLong}
                                                onChange={(e) =>
                                                    setDataTmp({
                                                        ...dataTmp,
                                                        furnishingNoteLong: e.target.value,
                                                    })
                                                }
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                            <div className="row mt-4">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="form-group col-lg-12">
                                                        <label>Lage (nicht öffentlich)</label>
                                                        <div className="input-group">
                                                            <textarea
                                                                className="form-control"
                                                                rows={30}
                                                                value={dataTmp.locationNoteLong}
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        locationNoteLong: e.target.value,
                                                                    })
                                                                }
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="form-group col-lg-12">
                                                        <label>Beschreibung Kurz-Exposé (nicht öffentlich)</label>
                                                        <div className="input-group">
                                                            <textarea
                                                                className="form-control"
                                                                rows={30}
                                                                value={dataTmp.descriptionShortExpose}
                                                                onChange={(e) =>
                                                                    setDataTmp({
                                                                        ...dataTmp,
                                                                        descriptionShortExpose: e.target.value,
                                                                    })
                                                                }
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-two-pictures" role="tabpanel" aria-labelledby="custom-tabs-two-pictures-tab">
                                            <div className="row mt-4">
                                                {/* <div className="form-group col-lg-12">
                                    <label>Objekt-Überblick (Expose-Seite)</label>
                                    <div className="input-group">
                                        <textarea
                                            className="form-control"
                                            rows={30}
                                            value={dataTmp.objectOverview}
                                            onChange={(e) =>
                                                setDataTmp({
                                                    ...dataTmp,
                                                    objectOverview: e.target.value,
                                                })
                                            }
                                        ></textarea>
                                    </div>
                                </div> */}
                                            </div>
                                            <div className="row mt-4">
                                                <section className="container">
                                                    <div {...getRootProps({ className: styles.dropzone })}>
                                                        <input {...getInputProps()} />
                                                        <p>Immobilienbilder hier hinziehen</p>
                                                    </div>
                                                </section>
                                            </div>
                                            {dataTmp.pictures ? (
                                                <>
                                                    <div className="row mt-4">
                                                        {Object.entries(dataTmp.pictures).map((picture: any, index: number) => (
                                                            <>
                                                                {objektPictures && objektPictures.includes(picture[0]) ? (
                                                                    <span className="position-relative m-1 text-center">
                                                                        <a
                                                                            href={`/api/ri/real-estate/objekt/files/${picture[0]}`}
                                                                            target="_blank"
                                                                            style={{ cursor: 'grab' }}
                                                                            draggable={true}
                                                                            onDragOver={(e) => e.preventDefault()}
                                                                            onDrop={(e) => {
                                                                                const result: any = {
                                                                                    destination: {
                                                                                        index,
                                                                                    },
                                                                                    source: {
                                                                                        index: draggedImgId,
                                                                                    },
                                                                                };
                                                                                onDragEnd(result);
                                                                            }}
                                                                            onDragStart={(e) => setDraggedImgId(index)}
                                                                        >
                                                                            {dataTmp.pictures[picture[0]].floorplan ? (
                                                                                <div className="ribbon-wrapper ">
                                                                                    <div className="ribbon bg-success">Grundriss</div>
                                                                                </div>
                                                                            ) : null}
                                                                            {dataTmp.pictures[picture[0]].notPublic ? (
                                                                                <div className="ribbon-wrapper ribbon-lg">
                                                                                    <div className="ribbon bg-danger">nicht öffentlich</div>
                                                                                </div>
                                                                            ) : null}
                                                                            {dataTmp.pictures[picture[0]].notInExpose ? (
                                                                                <div className="ribbon-wrapper ribbon-xl">
                                                                                    <div className="ribbon bg-secondary">nicht in Exposé</div>
                                                                                </div>
                                                                            ) : null}
                                                                            <img
                                                                                style={{ width: '120px' }}
                                                                                className=""
                                                                                src={`/api/ri/real-estate/objekt/files/${picture[0].split('.')[0]}_thumbnail.${picture[0].split('.')[1]}`}
                                                                                alt="Bild nicht vorhanden"
                                                                            />
                                                                            {dataTmp.pictures[picture[0]].expose ? (
                                                                                <div className={styles.textBlock}>
                                                                                    <p>
                                                                                        {dataTmp.pictures[picture[0]].expose === 'Objektueberblick'
                                                                                            ? 'Objektüberblick'
                                                                                            : dataTmp.pictures[picture[0]].expose === 'LetzteSeite'
                                                                                            ? 'Letzte Seite'
                                                                                            : dataTmp.pictures[picture[0]].expose}
                                                                                    </p>
                                                                                </div>
                                                                            ) : null}
                                                                        </a>
                                                                    </span>
                                                                ) : (
                                                                    picture[0]
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="offset-lg-4 offset-xl-4 col-lg-4 col-xl-4">
                                                            <button
                                                                onClick={(e) => {
                                                                    ri_real_estate_get_pictures(dataTmp && dataTmp.externalId ? dataTmp.externalId : '');
                                                                }}
                                                                className={`btn btn-block btn-info mr-1 mb-1`}
                                                                disabled={dataTmp.pictures && Object.entries(dataTmp.pictures).length === 0}
                                                            >
                                                                Bilder herunterladen <i className="fas fa-download ml-2"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4 mb-4">
                                                        {Object.entries(dataTmp.pictures).map((picture: any, index: number) => (
                                                            <div className="col-lg-3 col-xl-3 col-m-12 col-sm-12 mt-3">
                                                                <div className="card card-outline card-primary h-100 ">
                                                                    <div className="card-header">
                                                                        {objektPictures && objektPictures.includes(picture[0]) ? (
                                                                            <div className="text-center">
                                                                                <a href={`/api/ri/real-estate/objekt/files/${picture[0]}`} target="_blank">
                                                                                    {dataTmp.pictures[picture[0]].floorplan ? (
                                                                                        <div className="ribbon-wrapper ">
                                                                                            <div className="ribbon bg-success">Grundriss</div>
                                                                                        </div>
                                                                                    ) : null}
                                                                                    {dataTmp.pictures[picture[0]].notPublic ? (
                                                                                        <div className="ribbon-wrapper ribbon-lg">
                                                                                            <div className="ribbon bg-danger">nicht öffentlich</div>
                                                                                        </div>
                                                                                    ) : null}
                                                                                    {dataTmp.pictures[picture[0]].notInExpose ? (
                                                                                        <div className="ribbon-wrapper ribbon-xl">
                                                                                            <div className="ribbon bg-secondary">nicht in Exposé</div>
                                                                                        </div>
                                                                                    ) : null}
                                                                                    <img
                                                                                        src={`/api/ri/real-estate/objekt/files/${picture[0].split('.')[0]}_small.${picture[0].split('.')[1]}`}
                                                                                        alt="Bild nicht vorhanden"
                                                                                        style={{ width: '100%' }}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        ) : (
                                                                            picture[0]
                                                                        )}
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-xl-12 mt-1">
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Bildbeschreibung (max. 22 Zeichen)"
                                                                                        value={dataTmp.pictures[picture[0]].titlePicture}
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].titlePicture = e.target.value;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        maxLength={22}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-2">
                                                                            <div className="col-xl-6 mt-3 text-center">
                                                                                <div className="input-group">
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].floorplan = e;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        className="align-middle"
                                                                                        checked={dataTmp.pictures[picture[0]].floorplan ? dataTmp.pictures[picture[0]].floorplan : false}
                                                                                        uncheckedIcon={false}
                                                                                    />
                                                                                    <label className="align-middle mt-1 ml-2">Grundriss</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 mt-3 text-center">
                                                                                <div className="input-group">
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].notPublic = e;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        className="align-middle"
                                                                                        checked={dataTmp.pictures[picture[0]].notPublic ? dataTmp.pictures[picture[0]].notPublic : false}
                                                                                        uncheckedIcon={false}
                                                                                    />
                                                                                    <label className="align-middle mt-1 ml-2">nicht öffentlich</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-xl-6 mt-3 text-center">
                                                                                <div className="input-group">
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].notInExpose = e;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        className="align-middle"
                                                                                        checked={dataTmp.pictures[picture[0]].notInExpose ? dataTmp.pictures[picture[0]].notInExpose : false}
                                                                                        uncheckedIcon={false}
                                                                                    />
                                                                                    <label className="align-middle mt-1 ml-2">nicht im Exposé</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-2">
                                                                            <div className="col-xl-12 mt-3">
                                                                                <div className={`form-group`}>
                                                                                    <label>Exposé-Position</label>
                                                                                    <div className="input-group">
                                                                                        <select
                                                                                            className={`form-control`}
                                                                                            onChange={(e) => {
                                                                                                let picturesTmp = dataTmp.pictures;
                                                                                                picturesTmp[picture[0]].expose = e.target.value;
                                                                                                if (e.target.value === '' && picturesTmp[picture[0]].pictureAdjustment) {
                                                                                                    delete picturesTmp[picture[0]].pictureAdjustment;
                                                                                                }
                                                                                                setDataTmp({
                                                                                                    ...dataTmp,
                                                                                                    pictures: picturesTmp,
                                                                                                });
                                                                                            }}
                                                                                            value={dataTmp.pictures[picture[0]].expose}
                                                                                        >
                                                                                            <option value=""></option>
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Coverbild"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Coverbild') ? (
                                                                                                <option value="Coverbild">Coverbild (quer)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Lageplan"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Lageplan') ? (
                                                                                                <option value="Lageplan">Lageplan (hoch)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Vollbild"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Vollbild') ? (
                                                                                                <option value="Vollbild">Vollbild</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Objektueberblick"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Objektueberblick') ? (
                                                                                                <option value="Objektueberblick">Objektüberblick</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Objektbeschreibung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Objektbeschreibung') ? (
                                                                                                <option value="Objektbeschreibung">Objektbeschreibung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Lagebeschreibung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Lagebeschreibung') ? (
                                                                                                <option value="Lagebeschreibung">Lagebeschreibung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Ausstattung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Ausstattung') ? (
                                                                                                <option value="Ausstattung">Ausstattung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Sonstiges"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Sonstiges') ? (
                                                                                                <option value="Sonstiges">Sonstige Angaben</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Energieausweis"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Energieausweis') ? (
                                                                                                <option value="Energieausweis">Energieausweis</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Heizung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Heizung') ? (
                                                                                                <option value="Heizung">Heizung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"LetzteSeite"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'LetzteSeite') ? (
                                                                                                <option value="LetzteSeite">Letzte Seite (quer)</option>
                                                                                            ) : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {picture[1].expose &&
                                                                        (picture[1].expose === 'Objektueberblick' ||
                                                                            picture[1].expose === 'Objektbeschreibung' ||
                                                                            picture[1].expose === 'Lagebeschreibung' ||
                                                                            picture[1].expose === 'Ausstattung' ||
                                                                            picture[1].expose === 'Sonstiges') ? (
                                                                            <div className="row">
                                                                                <div className="col-xl-12 mt-3">
                                                                                    <input
                                                                                        type="range"
                                                                                        className="custom-range"
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].pictureAdjustment = e.target.value;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        value={dataTmp.pictures[picture[0]].pictureAdjustment ? dataTmp.pictures[picture[0]].pictureAdjustment : 50}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ) : null}
                                                                        <div className="row">
                                                                            <div className="col-xl-12 mt-3">
                                                                                <div className={`form-group`}>
                                                                                    <label>Kurz-Exposé-Position</label>
                                                                                    <div className="input-group">
                                                                                        <select
                                                                                            className={`form-control`}
                                                                                            onChange={(e) => {
                                                                                                let picturesTmp = dataTmp.pictures;
                                                                                                picturesTmp[picture[0]].exposeShort = e.target.value;
                                                                                                setDataTmp({
                                                                                                    ...dataTmp,
                                                                                                    pictures: picturesTmp,
                                                                                                });
                                                                                            }}
                                                                                            value={dataTmp.pictures[picture[0]].exposeShort}
                                                                                        >
                                                                                            <option value=""></option>
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"exposeShort":"pic1"') ||
                                                                                            (picture[1].exposeShort && picture[1].exposeShort === 'pic1') ? (
                                                                                                <option value="pic1">links oben (quer)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"exposeShort":"pic2"') ||
                                                                                            (picture[1].exposeShort && picture[1].exposeShort === 'pic2') ? (
                                                                                                <option value="pic2">links mitte (quer)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"exposeShort":"pic3"') ||
                                                                                            (picture[1].exposeShort && picture[1].exposeShort === 'pic3') ? (
                                                                                                <option value="pic3">links unten (quer)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"exposeShort":"pic4"') ||
                                                                                            (picture[1].exposeShort && picture[1].exposeShort === 'pic4') ? (
                                                                                                <option value="pic4">unten mitte (hoch)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"exposeShort":"pic5"') ||
                                                                                            (picture[1].exposeShort && picture[1].exposeShort === 'pic5') ? (
                                                                                                <option value="pic5">unten rechts (hoch)</option>
                                                                                            ) : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-footer">
                                                                        <div className="row mb-1">
                                                                            <div className="col-lg-6 col-xl-6 col-m-12 col-sm-12 mt-1">
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        setDataTmp({
                                                                                            ...dataTmp,
                                                                                            pictures: moveObjectElement(
                                                                                                `${Object.keys(dataTmp.pictures)[index > 0 ? index - 1 : 0]}`,
                                                                                                picture[0],
                                                                                                dataTmp.pictures
                                                                                            ),
                                                                                        });
                                                                                    }}
                                                                                    className={`btn btn-sm btn-secondary btn-block`}
                                                                                    disabled={index === 0}
                                                                                >
                                                                                    <span className="d-none d-lg-block">
                                                                                        <i className="fas fa-arrow-left" /> Bild nach vorne
                                                                                    </span>
                                                                                    <span className="d-lg-none">
                                                                                        <i className="fas fa-arrow-up" /> Bild nach vorne
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6 col-m-12 col-sm-12 mt-1">
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        setDataTmp({
                                                                                            ...dataTmp,
                                                                                            pictures: moveObjectElement(
                                                                                                picture[0],
                                                                                                `${
                                                                                                    Object.keys(dataTmp.pictures)[index < Object.keys(dataTmp.pictures).length - 1 ? index + 1 : index]
                                                                                                }`,
                                                                                                dataTmp.pictures
                                                                                            ),
                                                                                        });
                                                                                    }}
                                                                                    className="btn btn-sm btn-secondary btn-block"
                                                                                    disabled={index === Object.keys(dataTmp.pictures).length - 1}
                                                                                >
                                                                                    <span className="d-none d-lg-block">
                                                                                        <i className="fas fa-arrow-right" /> Bild nach hinten
                                                                                    </span>
                                                                                    <span className="d-lg-none">
                                                                                        <i className="fas fa-arrow-down" /> Bild nach hinten
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-6 col-xl-6 col-m-12 col-sm-12 mt-1">
                                                                                <a href={`/api/ri/real-estate/objekt/files/${picture[0]}`} className="btn btn-info btn-block" target="_blank">
                                                                                    <i className="fas fa-eye" /> Bild öffnen
                                                                                </a>
                                                                            </div>
                                                                            <div className="col-lg-6 col-xl-6 col-m-12 col-sm-12 mt-1">
                                                                                <button onClick={(e) => deletePicture(picture[0])} className="btn btn-danger btn-block">
                                                                                    <i className="fas fa-trash" /> Bild löschen
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* <div className="row mt-4">
                                        <div className="col-lg-12 col-xl-12">
                                            <table className="table">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th style={{ borderTop: 'none', width: '20px' }}></th>
                                                        <th style={{ borderTop: 'none', width: '40px' }}>Bild</th>
                                                        <th style={{ borderTop: 'none' }}>Bildbeschriftung</th>
                                                        <th style={{ borderTop: 'none' }}>Grundriss</th>
                                                        <th style={{ borderTop: 'none' }}>nicht öffentlich</th>
                                                        <th style={{ borderTop: 'none' }}>nicht im Expose</th>
                                                        <th style={{ borderTop: 'none' }}>Exposé</th>
                                                        <th style={{ borderTop: 'none' }}>Größe</th>
                                                        <th style={{ borderTop: 'none' }} />
                                                    </tr>
                                                </thead>
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                                                {Object.entries(dataTmp.pictures).map((picture: any, index: number) => (
                                                                    <Draggable draggableId={index.toString()} index={index}>
                                                                        {(provided) => (
                                                                            <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <td className="align-middle">
                                                                                    <button
                                                                                        onClick={(e) => {
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: moveObjectElement(
                                                                                                    `${Object.keys(dataTmp.pictures)[index > 0 ? index - 1 : 0]}`,
                                                                                                    picture[0],
                                                                                                    dataTmp.pictures
                                                                                                ),
                                                                                            });
                                                                                        }}
                                                                                        className={`btn btn-sm btn-secondary mr-1 mb-1`}
                                                                                        disabled={index === 0}
                                                                                    >
                                                                                        <i className="fas fa-arrow-up" />
                                                                                    </button>
                                                                                    <button
                                                                                        onClick={(e) => {
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: moveObjectElement(
                                                                                                    picture[0],
                                                                                                    `${
                                                                                                        Object.keys(dataTmp.pictures)[
                                                                                                            index < Object.keys(dataTmp.pictures).length - 1 ? index + 1 : index
                                                                                                        ]
                                                                                                    }`,
                                                                                                    dataTmp.pictures
                                                                                                ),
                                                                                            });
                                                                                        }}
                                                                                        className="btn btn-sm btn-secondary"
                                                                                        disabled={index === Object.keys(dataTmp.pictures).length - 1}
                                                                                    >
                                                                                        <i className="fas fa-arrow-down" />
                                                                                    </button>
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    {objektPictures && objektPictures.includes(picture[0]) ? (
                                                                                        <a href={`/api/ri/real-estate/objekt/files/${picture[0]}`} target="_blank">
                                                                                            <img
                                                                                                src={`/api/ri/real-estate/objekt/files/${picture[0].split('.')[0]}_thumbnail.${
                                                                                                    picture[0].split('.')[1]
                                                                                                }`}
                                                                                                alt="Bild nicht vorhanden"
                                                                                            />
                                                                                        </a>
                                                                                    ) : (
                                                                                        picture[0]
                                                                                    )}
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    <div className="input-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            value={dataTmp.pictures[picture[0]].titlePicture}
                                                                                            onChange={(e) => {
                                                                                                let picturesTmp = dataTmp.pictures;
                                                                                                picturesTmp[picture[0]].titlePicture = e.target.value;
                                                                                                setDataTmp({
                                                                                                    ...dataTmp,
                                                                                                    pictures: picturesTmp,
                                                                                                });
                                                                                            }}
                                                                                            maxLength={22}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center pb-4">
                                                                                    <div>Grundriss</div>
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].floorplan = e;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        className="align-middle"
                                                                                        checked={dataTmp.pictures[picture[0]].floorplan ? dataTmp.pictures[picture[0]].floorplan : false}
                                                                                        uncheckedIcon={false}
                                                                                    />
                                                                                </td>
                                                                                <td className="align-middle text-center pb-4">
                                                                                    <div>nicht öffentlich</div>
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].notPublic = e;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        className="align-middle"
                                                                                        checked={dataTmp.pictures[picture[0]].notPublic ? dataTmp.pictures[picture[0]].notPublic : false}
                                                                                        uncheckedIcon={false}
                                                                                    />
                                                                                </td>
                                                                                <td className="align-middle text-center pb-4">
                                                                                    <div>nicht im Exposé</div>
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            let picturesTmp = dataTmp.pictures;
                                                                                            picturesTmp[picture[0]].notInExpose = e;
                                                                                            setDataTmp({
                                                                                                ...dataTmp,
                                                                                                pictures: picturesTmp,
                                                                                            });
                                                                                        }}
                                                                                        className="align-middle"
                                                                                        checked={dataTmp.pictures[picture[0]].notInExpose ? dataTmp.pictures[picture[0]].notInExpose : false}
                                                                                        uncheckedIcon={false}
                                                                                    />
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    <div className="col-lg-12 align-middle">
                                                                                        <select
                                                                                            className={`form-control`}
                                                                                            onChange={(e) => {
                                                                                                let picturesTmp = dataTmp.pictures;
                                                                                                picturesTmp[picture[0]].expose = e.target.value;
                                                                                                if (e.target.value === '' && picturesTmp[picture[0]].pictureAdjustment) {
                                                                                                    delete picturesTmp[picture[0]].pictureAdjustment;
                                                                                                }
                                                                                                setDataTmp({
                                                                                                    ...dataTmp,
                                                                                                    pictures: picturesTmp,
                                                                                                });
                                                                                            }}
                                                                                            value={dataTmp.pictures[picture[0]].expose}
                                                                                        >
                                                                                            <option value=""></option>
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Coverbild"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Coverbild') ? (
                                                                                                <option value="Coverbild">Coverbild (quer)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Lageplan"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Lageplan') ? (
                                                                                                <option value="Lageplan">Lageplan (hoch)</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Objektueberblick"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Objektueberblick') ? (
                                                                                                <option value="Objektueberblick">Objektüberblick</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Objektbeschreibung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Objektbeschreibung') ? (
                                                                                                <option value="Objektbeschreibung">Objektbeschreibung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Lagebeschreibung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Lagebeschreibung') ? (
                                                                                                <option value="Lagebeschreibung">Lagebeschreibung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Ausstattung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Ausstattung') ? (
                                                                                                <option value="Ausstattung">Ausstattung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Sonstiges"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Sonstiges') ? (
                                                                                                <option value="Sonstiges">Sonstige Angaben</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Energieausweis"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Energieausweis') ? (
                                                                                                <option value="Energieausweis">Energieausweis</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"Heizung"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'Heizung') ? (
                                                                                                <option value="Heizung">Heizung</option>
                                                                                            ) : null}
                                                                                            {!JSON.stringify(dataTmp.pictures).includes('"expose":"LetzteSeite"') ||
                                                                                            (picture[1].expose && picture[1].expose === 'LetzteSeite') ? (
                                                                                                <option value="LetzteSeite">Letzte Seite (quer)</option>
                                                                                            ) : null}
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    {picture[1].expose &&
                                                                                    (picture[1].expose === 'Objektueberblick' ||
                                                                                        picture[1].expose === 'Objektbeschreibung' ||
                                                                                        picture[1].expose === 'Lagebeschreibung' ||
                                                                                        picture[1].expose === 'Ausstattung' ||
                                                                                        picture[1].expose === 'Sonstiges') ? (
                                                                                        <input
                                                                                            type="range"
                                                                                            className="custom-range"
                                                                                            onChange={(e) => {
                                                                                                let picturesTmp = dataTmp.pictures;
                                                                                                picturesTmp[picture[0]].pictureAdjustment = e.target.value;
                                                                                                setDataTmp({
                                                                                                    ...dataTmp,
                                                                                                    pictures: picturesTmp,
                                                                                                });
                                                                                            }}
                                                                                            value={dataTmp.pictures[picture[0]].pictureAdjustment ? dataTmp.pictures[picture[0]].pictureAdjustment : 50}
                                                                                        />
                                                                                    ) : null}
                                                                                </td>
                                                                                <td className="text-right align-middle">
                                                                                    <div className="btn-group btn-group-sm">
                                                                                        <a href={`/api/ri/real-estate/objekt/files/${picture[0]}`} className="btn btn-info" target="_blank">
                                                                                            <i className="fas fa-eye" />
                                                                                        </a>
                                                                                        <button onClick={(e) => deletePicture(picture[0])} className="btn btn-danger">
                                                                                            <i className="fas fa-trash" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </Draggable>
                                                                ))}

                                                                {provided.placeholder}
                                                            </tbody>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </table>
                                        </div>
                                    </div> */}
                                                </>
                                            ) : null}
                                        </div>

                                        {dataTmp && (dataTmp.realEstateType === 'realestates.houseBuy' || dataTmp.realEstateType === 'realestates.livingBuySite') ? (
                                            <div className="tab-pane fade" id="custom-tabs-two-checklist" role="tabpanel" aria-labelledby="custom-tabs-two-checklist-tab">
                                                <div className="row mt-4">
                                                    <div className="col-lg-12 col-xl-12">
                                                        <div className="form-group col-lg-12">
                                                            <label>Immobilienkauf-Checkliste</label>
                                                            <div className="input-group">
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={30}
                                                                    value={dataTmp.realEstateBuyChecklist}
                                                                    onChange={(e) =>
                                                                        setDataTmp({
                                                                            ...dataTmp,
                                                                            realEstateBuyChecklist: e.target.value,
                                                                        })
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="tab-pane fade" id="custom-tabs-two-people" role="tabpanel" aria-labelledby="custom-tabs-two-people-tab">
                                            <div className="row">
                                                <div className="col-lg-3 col-xl-3 mt-auto mb-3">
                                                    <label>Eigentümer Passcode</label>
                                                    <div className="input-group mt-auto">
                                                        <div className="input-group-prepend">
                                                            <button
                                                                className="btn btn-outline-primary"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    copyToClipboard(
                                                                        dataTmp.ownerPasscode
                                                                            ? `https://rauprecht-immobilien.de/angebote-app/${dataTmp.externalId}/${dataTmp.ownerPasscode}`
                                                                            : `https://rauprecht-immobilien.de/angebote-app/${dataTmp.externalId}`
                                                                    );
                                                                    fireToast('info', `App URL im Zwischenspeicher`);
                                                                }}
                                                            >
                                                                App URL kopieren
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className={`form-control text-right mt-auto`}
                                                            placeholder=""
                                                            value={dataTmp.ownerPasscode ? dataTmp.ownerPasscode : ''}
                                                            onChange={(e) =>
                                                                setDataTmp({
                                                                    ...dataTmp,
                                                                    ownerPasscode: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                className="btn btn-outline-primary"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    copyToClipboard(
                                                                        dataTmp.ownerPasscode
                                                                            ? `https://rauprecht-immobilien.de/angebote/${dataTmp.externalId}?ownerPasscode=${dataTmp.ownerPasscode}`
                                                                            : `https://rauprecht-immobilien.de/angebote/${dataTmp.externalId}`
                                                                    );
                                                                    fireToast('info', `URL im Zwischenspeicher`);
                                                                }}
                                                            >
                                                                URL kopieren
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3 mt-auto mb-3">
                                                    <label>
                                                        Verspätung ab {dataTmp && dataTmp.stakeholderDelayDays ? dataTmp.stakeholderDelayDays : 10} Tag
                                                        {dataTmp && dataTmp.stakeholderDelayDays && dataTmp.stakeholderDelayDays === 1 ? '' : 'en'}
                                                    </label>
                                                    <div className="input-group mt-auto">
                                                        <input
                                                            type="range"
                                                            className="custom-range"
                                                            onChange={(e) => {
                                                                setDataTmp({
                                                                    ...dataTmp,
                                                                    stakeholderDelayDays: Math.floor(Number(e.target.value) + 1),
                                                                });
                                                            }}
                                                            value={dataTmp && dataTmp.stakeholderDelayDays ? Math.floor(Number(dataTmp.stakeholderDelayDays) - 1) : 11}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-xl-3 mt-auto mb-3">
                                                    <button className="btn btn-info btn-block" onClick={(e) => ri_real_estate_get_stakeholder_list(objekt.objectId)}>
                                                        Interessenten-Liste herunterladen
                                                    </button>
                                                </div>
                                                <div className="col-lg-3 col-xl-3 mt-auto mb-3">
                                                    <button className="btn btn-danger btn-block" onClick={(e) => deleteAllStakeholder(objekt.objectId)}>
                                                        Alle Interessenten löschen
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="row">
                                                        <div className={`col-lg-3 mb-3`}>
                                                            <div className={`card card-row card-secondary h-100`}>
                                                                <div className="card-header">
                                                                    <h3 className="card-title">Exposé verschickt</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div id="newStakeholderCard" className="card card-success mb-1 collapsed-card mb-3">
                                                                        <div className="btn btn-secondary btn-block" data-card-widget="collapse" style={{ cursor: 'pointer' }}>
                                                                            Interessenten anlegen
                                                                        </div>
                                                                        <div id="newStakeholderCardBody" className="card-body box-profile">
                                                                            <div className="text-center">
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="Anfrage erhalten am (z.B. 03.03.2022, 09:00)"
                                                                                        value={newStakeholder && newStakeholder.requestReceivedAt ? newStakeholder.requestReceivedAt : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                requestReceivedAt: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="mt-2 input-group mb-3">
                                                                                    <select
                                                                                        className="form-control"
                                                                                        onChange={(e) => {
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                title: e.target.value,
                                                                                            });
                                                                                        }}
                                                                                        value={newStakeholder && newStakeholder.title ? newStakeholder.title : 'Herr'}
                                                                                    >
                                                                                        <option value="Herr">Herr</option>
                                                                                        <option value="Frau">Frau</option>
                                                                                        <option value="Familie">Familie</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="Vorname"
                                                                                        value={newStakeholder && newStakeholder.firstName ? newStakeholder.firstName : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                firstName: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="Nachname"
                                                                                        value={newStakeholder && newStakeholder.lastName ? newStakeholder.lastName : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                lastName: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="Telefon"
                                                                                        value={newStakeholder && newStakeholder.telephone ? newStakeholder.telephone : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                telephone: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="E-Mail"
                                                                                        value={newStakeholder && newStakeholder.email ? newStakeholder.email : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                email: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <textarea
                                                                                        className={`form-control control-border`}
                                                                                        rows={7}
                                                                                        placeholder="Anfrage-Text"
                                                                                        value={newStakeholder && newStakeholder.requestText ? newStakeholder.requestText : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                requestText: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="Straße"
                                                                                        value={newStakeholder && newStakeholder.street ? newStakeholder.street : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                street: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className={`form-control control-border`}
                                                                                        placeholder="PLZ und Ort"
                                                                                        value={newStakeholder && newStakeholder.city ? newStakeholder.city : ''}
                                                                                        onChange={(e) =>
                                                                                            setNewStakeholder({
                                                                                                ...newStakeholder,
                                                                                                city: e.target.value,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <button
                                                                                    className="btn btn-primary btn-block"
                                                                                    onClick={(e) => {
                                                                                        ri_real_estate_create_stakeholder(newStakeholder, objekt.objectId);
                                                                                        setNewStakeholder({});
                                                                                        document.getElementById('newStakeholderCard')!.classList.add('collapsed-card');
                                                                                        document.getElementById('newStakeholderCardBody')!.setAttribute('style', 'display:none;');
                                                                                    }}
                                                                                    disabled={!newStakeholder || (newStakeholder && (!newStakeholder.email || !newStakeholder.lastName))}
                                                                                >
                                                                                    <b>Interessent anlegen</b>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {stakeholder
                                                                        ? stakeholder.map((stakeholder: any) => {
                                                                              if (!stakeholder.status || stakeholder.status === 'EXPOSE') {
                                                                                  return stakeholderCard(stakeholder);
                                                                              } else {
                                                                                  return null;
                                                                              }
                                                                          })
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-lg-3 mb-3`}>
                                                            <div className={`card card-row card-info h-100`}>
                                                                <div className="card-header">
                                                                    <h3 className="card-title">Termin vereinbart</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    {stakeholder
                                                                        ? stakeholder.map((stakeholder: any) => {
                                                                              if (stakeholder.status === 'TERMIN') {
                                                                                  return stakeholderCard(stakeholder);
                                                                              } else {
                                                                                  return null;
                                                                              }
                                                                          })
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-lg-3 mb-3`}>
                                                            <div className={`card card-row card-success h-100`}>
                                                                <div className="card-header">
                                                                    <h3 className="card-title">Weiteres Interesse</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    {stakeholder
                                                                        ? stakeholder.map((stakeholder: any) => {
                                                                              if (stakeholder.status === 'AUSWAHL') {
                                                                                  return stakeholderCard(stakeholder);
                                                                              } else {
                                                                                  return null;
                                                                              }
                                                                          })
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-lg-3 mb-3`}>
                                                            <div className={`card card-row card-secondary h-100`}>
                                                                <div className="card-header">
                                                                    <h3 className="card-title">Abgesagt / Ausgeschieden</h3>
                                                                </div>
                                                                <div className="card-body">
                                                                    {stakeholder
                                                                        ? stakeholder.map((stakeholder: any) => {
                                                                              if (stakeholder.status === 'AUSGESCHIEDEN') {
                                                                                  return stakeholderCard(stakeholder);
                                                                              } else {
                                                                                  return null;
                                                                              }
                                                                          })
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        id="submit_button"
                                        className="btn btn-danger float-left"
                                        style={{ marginBottom: '5px', marginRight: '5px' }}
                                        onClick={(e) => deleteObjekt(objekt.objectId, dataTmp.title ? dataTmp.title : '')}
                                    >
                                        Löschen
                                    </button>
                                    <button
                                        id="submit_button"
                                        ref={saveButton}
                                        className="btn btn-primary float-right"
                                        style={{ marginBottom: '5px', marginRight: '5px' }}
                                        onClick={(e) => ri_real_estate_change_objekt(objekt.objectId, dataTmp, publishedHomepage)}
                                        disabled={!checkIfSavePossible()}
                                    >
                                        {objektUpdating ? <div className={styles.loader} /> : 'Speichern (CMD+S)'}
                                    </button>
                                    {/* <button onClick={(e) => ri_real_estate_get_expose(dataTmp, 'input.docx')} className="mr-2 btn btn-info float-right">
                                Exposé herunterladen
                            </button>
                            <button onClick={(e) => ri_real_estate_get_expose(dataTmp, 'input_alternative.docx')} className="mr-2 btn btn-info float-right">
                                Exposé Alternative herunterladen
                            </button> */}
                                    {dataTmp.realEstateType === 'realestates.houseBuy' || dataTmp.realEstateType === 'realestates.livingBuySite' ? (
                                        <button ref={notarButton} onClick={(e) => ri_real_estate_get_expose(dataTmp, 'pdfNotar')} className="mr-2 mb-2 btn btn-info float-right">
                                            Kaufvertrag-Check herunterladen (CMD+B)
                                        </button>
                                    ) : null}
                                    <button ref={shortExposeButton} onClick={(e) => ri_real_estate_get_expose(dataTmp, 'pdfShortExpose')} className="mr-2 mb-2 btn btn-info float-right">
                                        Kurz-Exposé herunterladen (CMD+K)
                                    </button>
                                    <button ref={exposeButton} onClick={(e) => ri_real_estate_get_expose(dataTmp, 'pdfExpose')} className="mr-2 mb-2 btn btn-info float-right">
                                        Exposé herunterladen (CMD+E)
                                    </button>
                                    <button onClick={(e) => history.push(`/ri/real-estate/objects/`)} className="mr-2 mb-2 btn btn-secondary float-right">
                                        Abbrechen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        <button onClick={(e) => window.scrollTo(0, document.body.scrollHeight)} className={styles.fixedButton} title="Go to top">
                            <i className="fas fa-chevron-down" />
                        </button>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default RIRealEstateObjectsDetailApp;
